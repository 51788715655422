

export default function PushNotifications(){

}

PushNotifications.prototype = {
    registerAndSubscribeNotification: async function() {
        let sw = await navigator.serviceWorker.register('./sw.js');
        console.log(sw);
        return await this.subscribe();
    },

    checkForNotificationPermissions: async function() {
        //console.log("...checking for notifications permission");
        if (window.Notification && Notification.permission === "granted") {
            return await this.registerAndSubscribeNotification();
        }

        // If the user hasn't told if they want to be notified or not
        // Note: because of Chrome, we are not sure the permission property
        // is set, therefore it's unsafe to check for the "default" value.
        else if (window.Notification && Notification.permission !== "denied") {
            var self = this;
            Notification.requestPermission(async function (status) {
                // If the user said okay
                if (status === "granted") {
                    return await self.registerAndSubscribeNotification();
                }
            });
        }
        return {"retCode": 0};
    },

    subscribe: async function() {
        let sw = await navigator.serviceWorker.ready;
        let push = await sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BJTgpvQBs3U6OdSiX_RL-MUeSt81ljORwXHQdYigSLBUcdMUfeSA7CObwtfrZkWyUdJ25YiO8KuABhkjzHcE6D0'
        })
       // console.log("subscription",JSON.stringify(push));

        // Opera 8.0+
        var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]" 
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;

        // Chrome 1 - 71
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        var output = 'Detecting browsers by ducktyping:\n';
        var browser = "unknown";
        if (isChrome) {
            browser = "chrome";
        } else if (isFirefox) {
                browser = "firefox";
        } else if (isEdge) {
            browser = "edge";
        } else if (isIE) {
            browser = "ie";
        } else if (isSafari) {
            browser = "safari";
        } else if (isOpera) {
            browser = "opera";
        }

        console.log("Browser detect", browser);
        return {
            "retCode": 1,
            "browser": browser,
            "subscription": JSON.stringify(push)
        }
    }

}