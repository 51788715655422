import React from 'react';
import styled from 'styled-components'
import { Label, Table } from 'semantic-ui-react'
import StyledMoney from './StyledMoney';
import StyledNumber from './StyledNumber';
import CopyToClipboard from './CopyToClipboard/CopyToClipboard';
import HideListing from './HideListing/HideListing';
import StateMap from './states';


const Frame = styled.span`
  display:inline-block;
  margin:0 0.3em;
  padding:0 0.5em;
  background: orange;
  border-radius:5px;
  color:#FFFFFF;
  font-size:0.7em;
`

const SellerWrapper = styled.div`
  display:inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`
/*

const Lmpy = styled.span`
  display:inline-block;
  margin:0 0.3em;
  padding:0 0.5em;
  background: #a333c8;
  border-radius:5px;
  color:#FFFFFF;
  font-size:0.7em;
`

const calcLmpy = function(mileage,year){
   return false; 
   var d = new Date();
    var y = parseInt(d.getFullYear());
    var yearsPassed = (year === 2020) ? 1 : (y - year);
    return ((mileage/yearsPassed) > 10000) ? false : true;
}

*/

//TODO: REFACTOR TO REMOVE DUPLICATE CODE

const renderColumns = {
    'vin': (vehicle, key, markAsRead)=>{

        return <Table.Cell key={key} onClick={(e)=>{e.stopPropagation()}}>
            {vehicle.vin}&nbsp;&nbsp;
            <CopyToClipboard vin={vehicle.vin} markAsRead={markAsRead}/>
        </Table.Cell>

    },

    'createdDate': (vehicle, key)=>{
        var d = new Date(vehicle['createdDate']);
        var now = new Date();
        var tzAdjustedDate = new Date( d.getTime() - (now.getTimezoneOffset()*60*1000 ) );
        return <Table.Cell key={key}>
           {tzAdjustedDate.toLocaleString('en-US', { timeZone: 'America/New_York' })}
           
        </Table.Cell>
    },

    'auctionStartDate': (vehicle, key)=>{
        var d = new Date(vehicle['auctionStartDate']);
        return <Table.Cell key={key}>
            {d.toLocaleString('en-US', { timeZone: 'America/New_York' })}
        </Table.Cell>
    },

    'auctionEndDate': (vehicle, key)=>{
        var d = new Date(vehicle['auctionEndDate']);
        return <Table.Cell key={key}>
            {d.toLocaleString('en-US', { timeZone: 'America/New_York' })}
        </Table.Cell>
    },

    'mileage': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledNumber value={vehicle.mileage}/>&nbsp;
        </Table.Cell>
    },

    'pickupLocation': (vehicle, key)=>{
        return <Table.Cell key={key}>
            {vehicle.pickupLocation}, {vehicle.pickupLocationState}
        </Table.Cell>
    },

    'pickupLocationState': (vehicle, key)=>{
        return <Table.Cell key={key}>
            {StateMap[vehicle.pickupLocationState]} ({vehicle.locationZip})
        </Table.Cell>
    },

    'buyNowPrice': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledMoney value={vehicle['buyNowPrice']}/>
        </Table.Cell>
    },

    'currentBidPrice': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledMoney value={vehicle['currentBidPrice']}/>
        </Table.Cell>
    },

    'CRScore': (vehicle,key) =>{
        var frameDamage = '';
        if(vehicle['structuralDamage'] || parseInt(vehicle['frameDamage']) || vehicle['structuralAlteration']){
            frameDamage = <Frame color="orange" title="Frame Damage">!</Frame>
        }
        if(vehicle['CRScore'] !== "NULL") return <Table.Cell key={key}>{vehicle['CRScore']} {frameDamage}</Table.Cell>
        if(vehicle['grade'] && vehicle['grade'] !== "NULL") return <Table.Cell key={key}>{vehicle['grade']} {frameDamage}</Table.Cell>
        return <Table.Cell key={key}><Label size="tiny">Unavailable</Label></Table.Cell>
    },

    'currentBidToMmr': (vehicle, key)=>{
        if(vehicle['currentBidToMMR']){
            var b2mmr =  (parseFloat(vehicle['currentBidToMMR']) / parseFloat(vehicle['adjMmr']))*100;

            return <Table.Cell key={key}>
                <StyledMoney value={vehicle['currentBidToMMR'].toFixed(0)} inline={true}/>
                &nbsp;&nbsp;<small>({b2mmr.toFixed(2)+"%"})</small></Table.Cell>
        }
        else{
            return <Table.Cell key={key}> - </Table.Cell>
        }
 
    },
    'buyNowToMmr': (vehicle, key)=>{
        if(vehicle['buyNowToMMR']){
            var b2mmr =  (parseFloat(vehicle['buyNowToMMR']) / parseFloat(vehicle['adjMmr']))*100;
            return <Table.Cell key={key}>
                <StyledMoney value={vehicle['buyNowToMMR'].toFixed(0)} inline={true}/>
                &nbsp;&nbsp;<small>({b2mmr.toFixed(2)+"%"})</small></Table.Cell>
        }
        else{
            return <Table.Cell key={key}> - </Table.Cell>
        }
    },
    'sellerName': (vehicle, key)=>{
        if(vehicle['sellerName']){
            return <Table.Cell key={key} onClick={(e)=>{e.stopPropagation()}}>
                <SellerWrapper>{vehicle['sellerName']}&nbsp;&nbsp;</SellerWrapper>
                <CopyToClipboard vin={vehicle['sellerName']} />
            </Table.Cell>
        }
    },
    'blackBook': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledMoney value={vehicle['BlackBook']}/>
        </Table.Cell>
    },
    'buyNowToBlackBook': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledMoney value={vehicle['BuyNowToBlackBook']}/>
        </Table.Cell>
    },
    'currentBidToBlackBook': (vehicle, key)=>{
        return <Table.Cell key={key}>
            <StyledMoney value={vehicle['CurrentBidToBlackBook']}/>
        </Table.Cell>
    },
}

export default ({vehicle, columns, markAsRead, hideListing})=>{
    return <>
        {Object.keys(columns).map( (item, key)=>{
            return (!columns[item].active) ? null : (renderColumns[item]) ? renderColumns[item](vehicle, key, markAsRead) : <Table.Cell key={key}>
                {vehicle[columns[item].field]}
            </Table.Cell>
        })}
    </>
}