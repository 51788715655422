import React from 'react';
import styled from 'styled-components';
import StyledNumber from '../../../StyledNumber';
import CopyToClipboard from '../../../CopyToClipboard/CopyToClipboard';

const Headline = styled.h1`
    font-size:1.4em;
    flex-grow: 2
`
const Subheadline = styled.div`
    display:inline-block;
    font-size: 0.8em;
    color:#555555;
    padding:0.5em;
    text-decoration:italic;
    font-weight: normal;
    margin-left:0.5em;
`

export default ({vehicle}) =>{
    return(
    <Headline>
        {vehicle.year} {vehicle.make} {vehicle.model}
        {vehicle.extendedModel  && <Subheadline>{vehicle.extendedModel}</Subheadline>}
        {vehicle.trim && <Subheadline>{`(${vehicle.trim})`}</Subheadline> }
        <Subheadline><StyledNumber value={vehicle.mileage}/> miles</Subheadline>
        <Subheadline>{vehicle.drivetrain}</Subheadline>
        <Subheadline>{vehicle.vin}&nbsp;&nbsp;<CopyToClipboard vin={vehicle.vin}/></Subheadline>
    </Headline>
    );
}