import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import StyledNumber from '../../../StyledNumber';
import StyledMoney from '../../../StyledMoney';
import {Table} from 'semantic-ui-react';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';




export default ({vin})=>{

    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect( ()=>{
        var ignore = false;
        if(!vin) return;
        setLoading(true)
        var v = new VehicleManager();
        v.getVehicleDetail(vin, 'inventory').then( (result)=>{
            if(!ignore) setInventory(result.data['inventory']);
            setLoading(false)
        }).catch(()=>{setLoading(false)})
        return ()=>{ignore = true;}
    },[vin, setLoading])

    const outputInventory = (inv)=>{
        if(Array.isArray(inv) && inv.length > 0){
            return inv.map( (i,k)=>{
                return <Table.Row key={k}>
                    <Table.Cell>{i['store_id']}</Table.Cell>
                    <Table.Cell><StyledNumber value={i['mileage']}/></Table.Cell>
                    <Table.Cell><StyledMoney value={i['current_list_price']}/></Table.Cell>
                    <Table.Cell>{i['initial_live_date']}</Table.Cell>
                    <Table.Cell>{i['buyers_guide_code']}</Table.Cell>
                    <Table.Cell>{i['extcolor']}</Table.Cell>
                </Table.Row>
            })
        }
        else if(loading){
            return <Table.Row>
            <Table.Cell colSpan="4">Checking for inventory...</Table.Cell>
        </Table.Row>
        }
        else{
            return <Table.Row>
                <Table.Cell colSpan="4">No Inventory Data Available</Table.Cell>
            </Table.Row>
        }
    }


    return(
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>STORE</Table.HeaderCell>
                    <Table.HeaderCell>MILEAGE</Table.HeaderCell>
                    <Table.HeaderCell>LIST PRICE</Table.HeaderCell>
                    <Table.HeaderCell>DAYS ON LOT</Table.HeaderCell>
                    <Table.HeaderCell>WARRANTY</Table.HeaderCell>
                    <Table.HeaderCell>EXTERIOR</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            {outputInventory(inventory)}
        </Table>
    )
}