import React, {useState, useEffect} from 'react';
import {Application} from 'react-rainbow-components';
import OVE from './channels/OVE/OVE';
import DealerBlock from './channels/DealerBlock/DealerBlock';
import BMW from './channels/BMW/BMW'
import Channels from './channels/Channels';
import VehicleManager from './services/vehicles/VehicleManager';

import {
  BrowserRouter as Router, 
  Route, 
  Switch, 
  useParams
} from 'react-router-dom';
import PushNotifications from './pushNotifications';


function App(props) {
  const [once, setOnce] = useState(false);
  var pushNotifications = new PushNotifications();
  var v = new VehicleManager();

  useEffect( ()=>{
    pushNotifications.checkForNotificationPermissions().then((data)=>{
      if (data.retCode===1) {
        console.log("for subscription update", data);
        v.updatePushSubscription(data).then((ret)=>{
          console.log("after updating subscription", ret);
        });  
      }
    });

    v.getPushSubscriptions().then( (data)=>{
      console.log("subscriptions", data);
    })
  }, [once])

  return (
    <Application>
      <Router>
      <Route exact path="/index.html">
          <Channels/>
        </Route>
        <Route exact path="/">
          <Channels/>
        </Route>
        <Route exact path="/channel/ove">
          <OVE acct={props.acct} />
        </Route>
        <Route exact path="/channel/dealerblock">
          <DealerBlock acct={props.acct} />
        </Route>
        <Route exact path="/channel/bmw">
          <BMW acct={props.acct} />
        </Route>
      </Router>
    </Application>
  );
}
export default App;
