import React, {useState, useEffect} from 'react';
import {CarFaxEffect} from './CarfaxEffect';
import NeedCarfaxToken from './NeedCarfaxToken/NeedCarfaxToken';
import CarfaxDetail from './CarfaxDetails/CarfaxDetails';
import {Loader} from 'semantic-ui-react'
import styled from 'styled-components'


const CarfaxWrapper = styled.div`
    padding:2em 0;
`


export default (props)=>{

    const [loading, setLoading] = useState(false)
    const [carfax, setCarFax] = useState(false)
    const [loadTrigger, setLoadTrigger] = useState(0);
    const [tokenValid, setTokenValid] = useState(false)

    useEffect( ()=>{
        var ignore = false;
        var tokenString = localStorage.getItem('carfax_access_token');
        var token = {}
        if(tokenString) token = JSON.parse(tokenString)
        var d = new Date();
        
        if(token['accessToken'] && token['expiration'] > d.getTime()){
            setLoading(true);
            setTokenValid(true);
            CarFaxEffect(props.vin, token['accessToken']).then( (resp)=>{
                if(!ignore){

                    if(resp.data.errors || !resp.data.data.dealerReport){
                        setCarFax(false)
                    }else{
                         setCarFax(resp.data.data);
                    }
                    setLoading(false);
                }
            })
        }
        else{
            setTokenValid(false);
        }

        return ()=>{ignore = true}
    },[loadTrigger, setLoading, props.vin] )

    return(<CarfaxWrapper>
        <div>
            <img src="/carfax.svg" className="carfax-logo"/>
        </div>    
        <div>           
            {loading && <Loader inline active/>}
            {(!loading && tokenValid && carfax) && <CarfaxDetail carfax={carfax}/>}
            {(!loading && !tokenValid) && <NeedCarfaxToken vin={props.vin} setLoadTrigger={setLoadTrigger} loadTrigger={loadTrigger}/>}
        </div>
    </CarfaxWrapper>
    )
}
