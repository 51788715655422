import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MSALProvider from './components/MSALProvider/MSALProvider'

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

 
ReactDOM.render(
    <MSALProvider>
      <App/>
    </MSALProvider>,
    document.getElementById('root')
);