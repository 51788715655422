import React, {useState, useEffect} from 'react';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import VehicleManager from '../../../../services/vehicles/VehicleManager';

const RefreshIcon = styled.div`
position: relative;
`

const BadgeLabel = styled.div`
position:absolute;
top:-14px;
right:-14px;
background:#a333c8;
color:#FFFFFF;
font-size:0.8em;
display:inline-block;
border-radius:10px;
padding:3px;
text-align:center;
min-width:17px;
line-height:1em;
`

export default ({filters, sorters, lastRefresh, setRefresher, channel})=>{

    const [newListings, setNewListings] = useState(false);

    useEffect(()=>{
        var ignore = false;
        setNewListings(false);
        var checkForNew = setInterval( async ()=>{
            if(ignore) return;
            var v = new VehicleManager();
            var f = {...filters}
            var lastChk = new Date(lastRefresh);
            lastChk = (typeof lastChk === "object") ? lastChk : new Date(lastChk);
            f['createdDate_min'] = {value: lastChk, type:"range"}  ;
            f['limit'] = {value:1, type:"range"}; //keeps the payload small
            try{
                var data = await v.getVehicles({sorter:["createdDate_DESC"], filters:f}, channel);
                if(data.data.total_count){
                    setNewListings(true);
                }
            }catch(err){
                console.log(err);
            }   
        }, 300000)
        return ()=>{
            clearInterval(checkForNew);
            ignore = true;
        }
    },[filters, sorters, lastRefresh, channel]);

    return  <RefreshIcon onClick={()=>{setRefresher(Date.now()) }}>
        <Icon name="refresh" fitted size="large"/>
        {newListings ? <BadgeLabel>!</BadgeLabel> : null }
    </RefreshIcon>
}