import React from 'react'
import {Button} from 'semantic-ui-react';




export default ({vehicle, recordClickEvent, channel})=>{


    const updateFollow = ()=>{
        recordClickEvent(!vehicle.followUp)
    }

    const outputButton = ()=>{
        return <Button color={vehicle.followUp ? 'grey': 'black'}  onClick={updateFollow}>{vehicle.followUp ? 'Unfollow' : 'Follow'}</Button>
    }


    return(
        <>{vehicle && outputButton()}</>
    )
}