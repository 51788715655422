import React, {useState, useEffect} from 'react';
import {Accordion, Icon, Message} from 'semantic-ui-react';
import ImageGallery from '../DetailTabs/ImageTab/ImageGallery';
import Announcements from '../DetailTabs/OverviewTab/Announcements/Announcements';
import CRDetails from './CRDetails/CRDetails';
import NOCRData from './NoCRData/NoCRData';
import Pricing from './Pricing/Pricing';
import Seller from './Seller/Seller';
import ConditionSummary from './Condition/Condition';
import Features from './Features/Features';
import Attributes from './Attributes/Attributes';
import Auction from './Auction/Auction';
import styled from 'styled-components';
import Preferences from '../../../../../lib/Preferences';
import PrequalAlert from './PrequalAlert/PrequalAlert';
import Inventory from './Inventory/Inventory';
import SalesHistory from './SalesHistory/SalesHistory';  
import BidHistory from './BidHistory/BidHistory';  
import CompetitorData from './CompetitorData/CompetitorData';  
import Carfax from './Carfax/Carfax';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`
const LeftPane = styled.div`
    width:50%;
    padding:1em;
`

const RightPane = styled.div`
    width:48%;
    padding: 1em;
`

const MarginContent = styled.div`
    margin-left:10px;
`
const DetailLabel = styled.span`
    font-size:0.8em;
    text-align:left;
    text-transform: uppercase;
    font-weight:bold;
    margin:0;
    color:#FFFFFF;
    display:inline-block;
    padding:.5em;
`

export default ({vehicle, loading, channel})=>{
    var pref = new Preferences();
    pref.loadPreferences();
    var accordions = (Array.isArray(pref.preferences['leftAccordion'])) ? pref.preferences['leftAccordion'] : [0,1,2,3];
    pref.preferences['leftAccordion'] = accordions;
    const [accordionState, setAccordionState ] = useState(accordions);

    const titleClick = (event, data)=>{
        if(data.active){
            var filteredAccordion = accordionState.filter(i=>i !== data.index);
            setAccordionState([...filteredAccordion])
        }
        else{
            if(!accordionState.includes(data.index)) setAccordionState([...accordionState,data.index]);
        }
    }

    useEffect(()=>{
        pref.updatePreference('leftAccordion', accordionState)
    },[accordionState])


    const leftPanels = [
        {
            key:"inventory",
            title:{className: "navyLabel",content:<DetailLabel>Current Inventory</DetailLabel>},
            content:{content: <Inventory vin={vehicle.vin}  />},
            onTitleClick: titleClick
        },
        {
            key:"sales_history",
            title:{className: "navyLabel",content:<DetailLabel>Sales History</DetailLabel>},
            content:{content: <SalesHistory vin={vehicle.vin}  />},
            onTitleClick: titleClick
        },
        {
            key:"conditions",
            title:{
                className: "navyLabel",
                content:<DetailLabel>Condition Details</DetailLabel>
            },
            content:{content: <ConditionSummary vehicle={vehicle} channel={channel}/>},
            onTitleClick: titleClick
        },
        {
            key:"bid_history",
            title:{className: "navyLabel",content:<DetailLabel>Auction History</DetailLabel>},
            content:{content: <BidHistory vin={vehicle.vin} vehicle={vehicle} />},
            onTitleClick: titleClick
        },
        {
            key:"competitor_data",
            title:{className: "navyLabel",content:<DetailLabel>Competitor Data</DetailLabel>},
            content:{content: <CompetitorData vin={vehicle.vin} vehicle={vehicle} channel={channel}/>},
            onTitleClick: titleClick
        },
        {
            key:"features",
            title:{className: "navyLabel",content:<DetailLabel>Vehicle Options</DetailLabel>},
            content:{content: <Features vehicle={vehicle} />},
            onTitleClick: titleClick
        },
        {
            key:"attributes",
            title:{className: "navyLabel",content:<DetailLabel>Vehicle Specifications</DetailLabel>},
            content:{content: <Attributes vehicle={vehicle} channel={channel} />},
            onTitleClick: titleClick
        },

        {
            key:"auction",
            title:{className: "navyLabel",content:<DetailLabel>Auction Details</DetailLabel>},
            content:{content: <Auction vehicle={vehicle} channel={channel}/>},
            onTitleClick: titleClick

        },

    ]

    return <Wrapper>    
        <LeftPane>
            {vehicle['announcements'] && <Announcements announcements={vehicle['announcements']}/>}
            {(vehicle['PrequalificationAlert'] && channel != 'DealerBlock') && <PrequalAlert message={vehicle['PrequalificationAlert']}/>}
            <Pricing vehicle={vehicle}/>
            <Seller vehicle={vehicle} channel={channel}/>

            <Accordion activeIndex={accordionState} panels={leftPanels} vertical="true" exclusive={false} channel={channel}/> 
            
        </LeftPane>
        <RightPane>
            <ImageGallery images={vehicle.images} loading={loading}/>
            <MarginContent>
                <Carfax vin={vehicle.vin}/>
                {(vehicle.crAPIEligible || channel == 'DealerBlock') && <CRDetails vehicle={vehicle} loading={loading} channel={channel}/>  }
                <NOCRData url={vehicle.crURL}/>
            </MarginContent>
        </RightPane>
    </Wrapper>
}