import React, {useState, useEffect} from 'react'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components';
import update from 'immutability-helper';

const FilterLabel = styled.h2`
    font-size:1em;
    margin:3em 0 0.8em 0;
    text-transform:uppercase;
    color:#6b6b6b;
    font-weight:700;
`

const RangeFilter = (props) => {

  let defaults = {
    min: (props.min) ? props.min.value : "",
    max: (props.max) ? props.max.value : ""
  }
  const [errors, setErrors] = useState({min:false, max: false})
  const [values, setValues] = useState(defaults);
  const filterRegex = new RegExp(/^[\-?\d.]+$/gi);
  useEffect( ()=>{
    var defs = update({}, 
      {$set:{
        min: (props.min) ? props.min.value : "",
        max: (props.max) ? props.max.value : ""
      }
    })
    setValues(defs);
  }, [props.min, props.max]);

  return <>
    <FilterLabel>{props.label}</FilterLabel>
    <Form size="mini">
        <Form.Group widths='equal'>
          <Form.Field
            label='min'
            control='input'
            placeholder='Min'
            error={errors.min}
            value={values.min}
            onChange={(e)=>{setValues({min: e.target.value, max: values.max})}}
            onBlur={(item)=>{
              var val = item.target.value;
              if(val.length > 0 && !filterRegex.test(val)){
                setErrors({min:true, max: errors.max});
                return false;
              }
              else{
                setErrors({min: false, max: errors.max});
              }
              props.updater(props.field+"_min", val);
            }}
          />
          <Form.Field
            label='max'
            control='input'
            placeholder='Max'
            error={errors.max}
            value={values.max}
            onChange={(e)=>{setValues({max: e.target.value, min: values.min})}}
            onBlur={(item)=>{
              var val = item.target.value;
              if(val.length > 0 && !filterRegex.test(val)){
                setErrors({min:errors.min, max: true});
                return false;
              }
              else{ 
                setErrors({min: errors.min, max: false});
              }
              props.updater(props.field+"_max", val);
            }}
          />
        </Form.Group>
    </Form>
  </>
}

export default RangeFilter;