import React from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';



export default (props)=>{
    return (
        <Modal.Actions>
        <Button color='red' onClick={props.closeAndRemove}>
             Remove Listing
        </Button>
    </Modal.Actions>
    )
}