import React from 'react';
import {Pagination} from 'semantic-ui-react';

const PAGE_SIZE = 50;


export default (props)=>{
    var totalPages = Math.ceil(props.totalRows / PAGE_SIZE);
    var currentPage = Math.round( ( props.offset / PAGE_SIZE)) + 1;

    currentPage = isNaN(currentPage) ? 1: currentPage;
    totalPages = isNaN(totalPages) ? 1 : totalPages;

    return <Pagination 
        activePage={currentPage} 
        totalPages={totalPages}
        ellipsisItem={null}
        siblingRange={3}
        onPageChange={(event, data)=>{
            var offset = (data.activePage - 1) * PAGE_SIZE;
            var filters = {...props.filters.list};
            filters['offset'] = {
                type:'range',
                field: 'offset',
                value: (offset) ? offset : 0
            };
            props.filters.updater(filters);
        }}
    />

}