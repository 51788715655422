import React from 'react';
import styled from 'styled-components';
import Loading from '../../../../../elements/Loading';


const DetailItem = styled.div`
    width:100%;
`

const DetailValue = styled.div`
    padding:0;
    font-size:1.1em;
    text-align:left;
    display: flex;
    align-items:top;
    justify-content: space-between;
`

const Column = styled.div`
    width:18%;
    flex-grow:1;
`

const Value = styled.div`
    flex-grow:1;
    padding:1em;
`

function contentColumns(arr) {
    var listSize = arr.length;
    var sliceSize = Math.ceil(listSize /5);
    var columnList = [];
    var startItem = 0;
    for(var i = 0; i < 5; i++){
        columnList.push(arr.slice(startItem,(startItem+sliceSize)));
        startItem += sliceSize;

    }
    return columnList;
}

export default ({vehicle, loading})=>{
    var opts = vehicle.vehicleOptions ? vehicle.vehicleOptions.sort() : ["Options unavailable"];
    var vehicleOptions = contentColumns(opts);

    const returnLogic = ()=>{
        if(loading) return <Loading />;
        return(
            <DetailValue>
            {
                    vehicleOptions.map( (item, key)=>{
                        return <Column key={key}>
                                {    
                                    item.map( (vopt, key)=>{
                                       return <Value key={vopt}>{vopt}</Value>
                                    })
                                }
                        </Column>

                    })
                }

            </DetailValue>
        )
    }

    return <DetailItem>
        {returnLogic()}
    </DetailItem>
}