import React, {useState, useEffect} from 'react';
import {Table, Button} from 'semantic-ui-react';
import styled from 'styled-components';
import VehicleManager from '../../../../../services/vehicles/VehicleManager'

const TireWrapper = styled.div`
    padding:1em 0;
    flex-grow:1;


    h2 {
        font-size:1.1em;
        font-weight:700;
    }
`
const construction = {'R':"Radial"}

export default ({vin}) =>{

    const [tires, setTires] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadTires, setLoadTires] = useState(false)

    useEffect( ()=>{
        var ignore = false;
        if(!vin || !loadTires) return;
        setLoading(true)
        var v = new VehicleManager();
        v.getVehicleDetail(vin, 'tires').then( (result)=>{
            if(!ignore) setTires(result.data['tires']);
            setLoading(false)
        }).catch(()=>{setLoading(false)})
        return ()=>{ignore = true;}
    },[vin, setLoading, loadTires])

    const loadTiresData = (e)=>{
        e.preventDefault();
        setLoadTires(true);
    }

    return <TireWrapper>
        <Table celled>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Depth</Table.HeaderCell>
                <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                <Table.HeaderCell>Size</Table.HeaderCell>
                <Table.HeaderCell>Construction</Table.HeaderCell>
                <Table.HeaderCell>Rim Size</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {tires && tires.map( (tire, index)=>{
                return <Table.Row key={index}>
                    <Table.Cell>{tire.depth}</Table.Cell>
                    <Table.Cell>{tire.manufacturer}</Table.Cell>
                    <Table.Cell>{tire.size !== "NULL" && tire.size}</Table.Cell>
                    <Table.Cell>{construction[tire.construction]}</Table.Cell>
                    <Table.Cell>{tire.rimDiameter !== "NULL" && tire.rimDiameter}</Table.Cell>
                </Table.Row>
            } )}
            {loading && <Table.Row><Table.Cell colSpan="5"><Button disabled>Loading Tires...</Button></Table.Cell></Table.Row>}
            {!loadTires && <Table.Row><Table.Cell><Button onClick={loadTiresData}>Load Tires</Button></Table.Cell></Table.Row> }
            </Table.Body>
        </Table>
    </TireWrapper>

}