import React from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';



export default (props)=>{
    return (
        <Modal.Actions>
        <Button color='green' onClick={props.submitDQ}>
            <Icon name='checkmark' /> Submit Disqualification
        </Button>
    </Modal.Actions>
    )
}