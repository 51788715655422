import React from 'react';
import {  Table,  } from 'semantic-ui-react'
import TableRowDetail from './details/TableRowDetails/TableRowDetails'
import TableRowListing from './TableRowListing';
import VehicleManager from '../../../services/vehicles/VehicleManager';


export default function OLOTableRow({vehicle, updater, fetcher, columns, disqualifier, markAsRead, markAsFollowed, removeListingFromState, channel}){
    var d = new Date();
    var rawStamp = new Date(vehicle.createdDate);
    var tzAdjustedStamp = new Date(rawStamp.getTime() - (d.getTimezoneOffset()*60000));
    var age = d.getTime() - tzAdjustedStamp.getTime();
    var classStr = ( (age < 1800000) && !vehicle.read_at) ? "bluestripe": "";
    classStr += (vehicle.read_at) ? "listing_read" : " bold";
    classStr = (vehicle.expanded) ? "" : classStr;

    const hideListing = (reason)=>{

      var v = new VehicleManager();
      v.markAsHidden(
        {
          vin: vehicle.vin,
          hideReason: reason
        }
      )
      v.markAsRead(vehicle.vin, channel);
      removeListingFromState();
    }

    const rowComponent = (expanded)=>{
      if(expanded){
        return <Table.Cell colSpan="14" className="nopad">
                <TableRowDetail updater={updater} fetcher={fetcher} vehicle={vehicle} disqualifier={disqualifier} channel={channel} markAsFollowed={markAsFollowed}/>
              </Table.Cell>
      }
      else{
        return <TableRowListing vehicle={vehicle} columns={columns} markAsRead={markAsRead} hideListing={hideListing} />
      }
    }


    return(
      <Table.Row  className={classStr} onClick={(e)=>{if(!vehicle.expanded){updater(e);}} }>
            {rowComponent(vehicle.expanded)}
      </Table.Row>
    );
}
