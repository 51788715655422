import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components';

const Wrapper = styled.div`
    height:100vh;
    width:100vw;
    background: #F6F2F6;
    position:relative;
`

const Container = styled.div`
    position:absolute;
    text-align:center;
    top:40%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    max-width:500px;
    min-height:150px;
    background:#FFFFFF;
    border-radius:8px;
    border:1px solid #d4d4d4;
    padding:2em;   

    h1{
        font-size:30px;
        color: #333333;
        margin:50px 0 30px;
    }

    p {
        font-size:1.4em;
        color:#555555;
        line-height:1.4em;
        margin:0 0 2em 0;
        padding:0;
    }

    a {
        font-size:1.4em;
        display:block;
        padding:0.5em;
    }
    a:hover {
        background:#F2F2F2;
        text-decoration:none;
    }
`

export default (props)=>{

    return <Wrapper>
        <Container>
            <h1>Choose a Venue</h1>
            <Link to="/channel/ove">Manheim OVE</Link>
            <Link to="/channel/dealerblock">Adesa DealerBlock</Link>
            <Link to="/channel/bmw">BMW On Demand</Link>
        </Container>
    </Wrapper>
}