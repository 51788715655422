import React from 'react';
import Skeleton from 'react-loading-skeleton';

const defSize = 12
export default (props)=>{
    return <>
        <Skeleton width="80%" height={props.height|| defSize}/><br/>
        <Skeleton width="70%" height={props.height|| defSize}/><br/>
        <Skeleton width="72%" height={props.height|| defSize}/><br/>
        <Skeleton width="60%" height={props.height|| defSize}/><br/>
        <Skeleton width="63%" height={props.height|| defSize}/><br/>
        <Skeleton width="45%" height={props.height|| defSize}/><br/>
        <Skeleton width="48%" height={props.height|| defSize}/><br/>
        <Skeleton width="38%" height={props.height|| defSize}/><br/>
        <Skeleton width="35%" height={props.height|| defSize}/><br/>
        <Skeleton width="20%" height={props.height|| defSize}/><br/>
    </>
}