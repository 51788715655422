import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import Polling from './pollingEffect';
import ManageWatchLists from './ManageWatchLists/ManageWatchLists';
import update from 'immutability-helper'
import Preferences from '../../lib/Preferences';
import WatchlistStore from '../../services/WatchlistStore';

const Badge = styled.div`
    display:inline-block;
    font-size:0.9em;
    padding:0.7em;
    text-align:center;
    border:0px solid rgba(255,255,255,0.1);
    border-radius:6px;
    font-weight:600;
    margin-right:2em;
    letter-spacing:0.02em;
    color:#FFFFFF;

    &:hover {
        background: rgba(255,255,255,0.1);
    }
`

const BadgeLabel = styled.div`
    background:#a333c8;
    color:#FFFFFF;
    font-size:0.9em;
    display:inline-block;
    margin:0 0 0 10px;
    border-radius:10px;
    padding:5px;
    text-align:center;
    min-width:22px;
    line-height:1em;
`

export default function Badges(props){
    const [runOnce] = useState(0);    
    const [loading, setLoading] = useState(true);
    const [watchLists, updateWatchLists] = useState([]);

    useEffect(Polling(watchLists, updateWatchLists, props.channel), [watchLists, props.channel]);

    useEffect( ()=>{
        setLoading(true);
        /*
        var p = new Preferences();
        p.loadPreferences();
        var w = Array.isArray(p.preferences['watchlists']) ? p.preferences['watchlists'] : [];
        */

        WatchlistStore.getAll(dataCallback);
 
    },[runOnce])

    const dataCallback = (success, data)=> {
        var w = [];
        if (success) {
            console.log(" Badges watchlist data", data);
            w = data;
            updateWatchLists(w);
            setLoading(false);
    
        } else {
            console.log(" Badges error in watchlist data");
             updateWatchLists(w);
             setLoading(false);
        }
    }

    const loadWatchlist = (list, key)=>{
        var f = update(list.filters, {$set: list.filters});
        props.vehicles.filters.updater(f)
        props.vehicles.sorters.updater(["createdDate_DESC"])
        var wl = [...watchLists];
        wl[key]['newListings'] = 0;
        wl[key]['lastChecked'] = new Date();
        /*
        var p = new Preferences();
        p.updatePreference('watchlists', wl);
        */
        updateWatchLists(wl);
    }

    return(<>
        {
            !loading &&
            <ManageWatchLists watchLists={watchLists} updateWatchLists={updateWatchLists} filterOptions={props.filterOptions}>
                <Badge>
                    MANAGE WATCH LISTS
                </Badge>
            </ManageWatchLists>
        
        }

        {!loading && watchLists && watchLists.map((item,key)=>{
            return <Badge key={key} onClick={(e)=>{loadWatchlist(item, key)}}>
                {item.name}
                {item.newListings > 0 && <BadgeLabel>{item.newListings}</BadgeLabel>}
            </Badge>
        })}
    </>
    )
}
