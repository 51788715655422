import React, {useState, useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components';
import Effects from '../../../AppEffect';
import _ from 'lodash';

const FilterLabel = styled.h2`
    font-size:1em;
    margin:3em 0 0.8em 0;
    font-weight:700;
    color:#6b6b6b;
    text-transform:uppercase;
    padding:0.5em 0;
`

const Reload = styled.a`
  display:block;
  text-decoration:underline;
  color:blue;
  font-size:0.8em;
  padding:1em 0 0 0;
  text-align:right;
`

const processOptions = (vals)=>{
  var opts = [];
  
  var dedupedVals = _.uniq(vals);
  dedupedVals.forEach((item, index)=>{
    opts.push({
      key: item,
      value: item,
      text: item
    })
  })
  return opts;
}

const Filter = (props) => {
  var defaults = props.currentState ? [...props.currentState.acceptedValues] : [];
  const [dropdownValue, setValue] = useState(defaults);
  useEffect(
    ()=>{
      var defaults = props.currentState ? [...props.currentState.acceptedValues] : [];
      setValue(defaults);
    },
    [props.currentState]
  )

  var update = false;

  const change = (e, {value})=>{
    setValue([...value]);
    if(update) clearTimeout(update);
    update = setTimeout( ()=>{
      props.handleOnChange(e, {value: [...value]});
    }, 500);
  }

  return <>
    <FilterLabel>{props.label}</FilterLabel>
    <Dropdown
        clearable
        fluid
        multiple
        search
        selection
        options={processOptions(props.options)}
        placeholder={`Select ${props.label}`}
        onChange={change}
        value={dropdownValue}
        noResultsMessage={props.noResultsMessage}
    />
    {props.setFilterOptions && <Reload onClick={()=>{Effects.loadFilterOptions(props.setFilterOptions)()}}>Refresh Dropdown Data</Reload>}
  </>
}

export default Filter