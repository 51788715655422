import React from 'react';
import {List} from 'semantic-ui-react';
import {Button} from 'react-rainbow-components';
import styled from 'styled-components';
import WatchlistStore from '../../../../services/WatchlistStore';

const Spacer = styled.div`
    padding: 2em 0;
`


export default (props)=>{

    const createNew = async ()=>{
        var w = {id: 0, name:"New Watchlist", filters:{}, newListings: 0, notification_flag: 1, lastChecked:new Date() };
        var list = [...props.watchlists, w];
        w = await WatchlistStore.add(w);
        props.updater(list);
        props.activate(w);
    }
    const activateSelected =(item, key) => {
        console.log("*** activating watchlist key=", key);
        props.activate(item);
    }
    return  <>
        <List divided relaxed>

            {props.watchlists && props.watchlists.map( (item, key)=>{
                return <List.Item key={key}>
                    <List.Icon name="list alternate" size="large" verticalAlign="middle"/>
                    <List.Content>
                        <List.Header as='a' onClick={()=>activateSelected(item, key)}>{item.name}</List.Header>
                    </List.Content>
                </List.Item>
            
            })}            
        </List>

        <Spacer>
            <Button onClick={createNew}>Create New Watchlist</Button>
        </Spacer>
    </>
}