import React, {Children, useState, useEffect, isValidElement, cloneElement} from 'react';
import Auth from '../../services/Auth';
import LoggedOut from '../LoggedOut/LoggedOut';

export default (props)=>{

    const [acct, setAcct] = useState(null)
    const [disabled, setDisabled] = useState(true)

    useEffect( ()=>{

        var checkIfLoggedIn = async ()=>{

            try {
                if(!acct){
                    var accounts = Auth.getAllAccounts();
                    var useAccount = false;
                    accounts = (Array.isArray(accounts)) ? accounts : []; 
                    for(var i = 0; i < accounts.length; i++ ) {
                        if(accounts[i].homeAccountId){
                            useAccount = accounts[i];
                            break;
                        }
                    }
                    if(useAccount){
                        var a = await Auth.acquireTokenSilent({account: useAccount}); 
                        setTimeout( ()=>{
                          setAcct(a);
                          setDisabled(false);
                        },1000);
                    }
                    else{
                        setDisabled(false);
                    }
                }
            } catch (err) {
                console.log('err');
                console.log(err);
                setDisabled(false);
            }
        }
        checkIfLoggedIn();
    },[acct] )

    const doLogin = async ()=>{
        var loginRequest = {
            scopes: ["Files.Read", "openid"] 
        };
        try {
            var a = await Auth.loginPopup(loginRequest);
            setAcct(a);
        }
        catch(err){
            console.log('login error');
            console.log(err);
        }
    }

    const childrenWithTokens = Children.map(props.children, (item)=>{
        return (isValidElement(item)) ? cloneElement(item, {acct: acct}) : item;
    })
    
    return <>
            {acct ?  childrenWithTokens : <LoggedOut loginFunc={doLogin} disabled={disabled} />}
    </>

}
