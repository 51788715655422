import React from 'react';
import {Modal, Header} from 'semantic-ui-react';



export default (props)=>{
    return (
        <>
        <Modal.Header>Vin #{props.vin} is now disqualified</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <p>Click below to remove this vehicle from your listing view.<br/><br/>The vehicle will automatically be removed during your next refresh and you will not see this vehicle again in the future.</p>
            </Modal.Description>
        </Modal.Content>
        </>
    )
}