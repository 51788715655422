import React, {useState, useEffect} from 'react';
import { Form } from 'semantic-ui-react'
import styled from 'styled-components';
import update from 'immutability-helper';


const FilterLabel = styled.h2`
    font-size:1em;
    margin:3em 0 0.8em 0;
    text-transform:uppercase;
    color:#6b6b6b;
    font-weight:700;
`


export default (props)=>{

      const [value, setValue] = useState("");

      useEffect( ()=>{
        if(props.value){ 
            setValue(props.value.value)
        }
        else{
            setValue("")
        }
      }, [props.value]);

    return(<>
        <FilterLabel>{props.label}</FilterLabel>
        <Form size="mini">
            <Form.Group widths='equal'>
            <Form.Field
                control='input'
                placeholder={props.placeholder}
                value={value}
                onChange={(e)=>{setValue(e.target.value)}}
                onBlur={(item)=>{
                    var val = item.target.value;
                    props.updater(props.field, val);
                }}
            />
            </Form.Group>
        </Form>
    </>
    )

}