export default {
    columns : {
        "version":1,
        "OVE":{
            vin: { label:"VIN", active: true, field: "vin"},
            year: { label:"Year", active: true, field: "year"},
            make: { label:"Make", active: true, field: "make"},
            model: { label:"Model", active: false, field: "model"},
            trim: { label:"Trim", active: true, field: "extendedModel"},
            CRScore: { label:"CR Score", active: true, field: "crScore"},
            mileage: { label:"Mileage", active: true, field: "mileage"},
            createdDate: { label:"Created Date", active: true, field: "createdDate"},
            pickupLocation: { label:"Pick Up Location", active: false, field:'pickupLocation'},
            auctionStartDate: { label:"Auction Start Date", active: false, field: "auctionStartDate"},
            auctionEndDate: { label:"Auction End Date", active: true, field: "auctionEndDate"},
            sellerName: {label: "Seller", active: false, field: "sellerName"},
            buyNowPrice: { label:"Buy Now Price", active: true, field: "buyNowPrice"},
            currentBidPrice: { label:"Current Bid Price", active: true, field: "currentBidPrice"},
            buyNowToMmr: {label:"Buy Now to MMR", active: true, field: "buyNowToMmr"},
            currentBidToMmr: {label: "Bid to MMR", active: false, field: "currentBidToMmr"}, 
            blackBook: {label:"BlkBk", active: false, field: "BlackBook"},
            currentBidToBlackBook: {label: "Bid to BlkBk", active: false, field: "CurrentBidToBlackBook"},
            buyNowToBlackBook: {label:"Buy Now to BlkBK", active: false, field: "BuyNowToBlackBook"}
        },
        "DealerBlock":{
            vin: { label:"VIN", active: true, field: "vin"},
            year: { label:"Year", active: true, field: "year"},
            make: { label:"Make", active: true, field: "make"},
            model: { label:"Model", active: true, field: "model"},
            trim: { label:"Trim", active: true, field: "extendedModel"},
            CRScore: { label:"CR Score", active: true, field: "crScore"},
            mileage: { label:"Mileage", active: true, field: "mileage"},
            createdDate: { label:"Created Date", active: true, field: "createdDate"},
            pickupLocationState: { label:"Pick Up Location", active: false, field:'pickupLocationState'},
            auctionEndDate: { label:"Auction End Date", active: true, field: "auctionEndDate"},
            sellerName: {label: "Seller", active: false, field: "sellerName"},
            buyNowPrice: { label:"Buy Now Price", active: true, field: "buyNowPrice"},
            currentBidPrice: { label:"Current Bid Price", active: true, field: "currentBidPrice"},
            buyNowToMmr: {label:"Buy Now to MMR", active: true, field: "buyNowToMmr"},
            currentBidToMmr: {label: "Bid to MMR", active: false, field: "currentBidToMmr"}, 
            blackBook: {label:"BlkBk", active: false, field: "BlackBook"},
            currentBidToBlackBook: {label: "Bid to BlkBk", active: false, field: "CurrentBidToBlackBook"},
            buyNowToBlackBook: {label:"Buy Now to BlkBK", active: false, field: "BuyNowToBlackBook"}
        },
        "BMW_GROUP_DIRECT":{
            vin: { label:"VIN", active: true, field: "vin"},
            year: { label:"Year", active: true, field: "year"},
            make: { label:"Make", active: true, field: "make"},
            model: { label:"Model", active: false, field: "model"},
            trim: { label:"Trim", active: true, field: "extendedModel"},
            CRScore: { label:"CR Score", active: true, field: "crScore"},
            mileage: { label:"Mileage", active: true, field: "mileage"},
            createdDate: { label:"Created Date", active: true, field: "createdDate"},
            pickupLocation: { label:"Pick Up Location", active: true, field:'pickupLocation'},
            auctionStartDate: { label:"Auction Start Date", active: false, field: "auctionStartDate"},
            auctionEndDate: { label:"Auction End Date", active: true, field: "auctionEndDate"},
            sellerName: {label: "Seller", active: false, field: "sellerName"},
            buyNowPrice: { label:"Buy Now Price", active: true, field: "buyNowPrice"},
            currentBidPrice: { label:"Current Bid Price", active: true, field: "currentBidPrice"},
            buyNowToMmr: {label:"Buy Now to MMR", active: true, field: "buyNowToMmr"},
            currentBidToMmr: {label: "Bid to MMR", active: false, field: "currentBidToMmr"}, 
            blackBook: {label:"BlkBk", active: false, field: "BlackBook"},
            currentBidToBlackBook: {label: "Bid to BlkBk", active: false, field: "CurrentBidToBlackBook"},
            buyNowToBlackBook: {label:"Buy Now to BlkBK", active: false, field: "BuyNowToBlackBook"}
        },

    }
}


//{"vin":{"label":"VIN","active":true,"field":"vin"},"year":{"label":"Year","active":true,"field":"year"},"make":{"label":"Make","active":true,"field":"make"},"model":{"label":"Model","active":true,"field":"model"},"trim":{"label":"Trim","active":false,"field":"extendedModel"},"CRScore":{"label":"CR Score","active":true,"field":"crScore"},"mileage":{"label":"Mileage","active":true,"field":"mileage"},"createdDate":{"label":"Created Date","active":true,"field":"createdDate"},"pickupLocation":{"label":"Pick Up Location","active":true,"field":"pickupLocation"},"auctionStartDate":{"label":"Auction Start Date","active":false,"field":"auctionStartDate"},"auctionEndDate":{"label":"Auction End Date","active":false,"field":"auctionEndDate"},"sellerName":{"label":"Seller","active":false,"field":"sellerName"},"buyNowPrice":{"label":"Buy Now Price","active":false,"field":"buyNowPrice"},"currentBidPrice":{"label":"Current Bid Price","active":false,"field":"currentBidPrice"},"buyNowToMmr":{"label":"Buy Now to MMR","active":true,"field":"buyNowToMmr"},"currentBidToMmr":{"label":"Bid to MMR","active":true,"field":"currentBidToMmr"},"blackBook":{"label":"BlkBk","active":true,"field":"BlackBook"},"currentBidToBlackBook":{"label":"Bid to BlkBk","active":true,"field":"CurrentBidToBlackBook"},"buyNowToBlackBook":{"label":"Buy Now to BlkBK","active":true,"field":"BuyNowToBlackBook"}}