import React, {useState, useEffect} from 'react';
import {Drawer} from 'react-rainbow-components';
import Watchlists from './WatchLists/Watchlists';
import WatchlistEditor from './WatchlistEditor/WatchlistEditor';

export default (props)=>{
    const [watchlists, setWatchlists] = useState([]);
    const [drawer, updateDrawer] = useState(false);
    const [activeWatchlist, setActiveWatchlist] = useState(null);
    useEffect(()=>{
        if(props.watchLists){
            setWatchlists(props.watchLists);
        }
    }, [props.watchLists, drawer]);

    return <>
        <span onClick={()=>{updateDrawer(true)}}>
            {props.children}
        </span>
        <Drawer 
            header="Manage Watchlists" 
            isOpen={drawer} 
            size="large" 
            slideFrom="right"
            onRequestClose={()=>{
                updateDrawer(false);
                setActiveWatchlist(null);
                props.updateWatchLists(watchlists);
            }}
        >
            {!activeWatchlist && 
                <Watchlists 
                    watchlists={watchlists} 
                    activate={setActiveWatchlist} 
                    updater={setWatchlists} 
                />
            }
            {watchlists && activeWatchlist  &&
                <WatchlistEditor 
                    watchlists={watchlists} 
                    activeWatchlist={activeWatchlist} 
                    updater={(w)=>{
                        setWatchlists(w);
                        props.updateWatchLists(watchlists);
                    }}
                    closeWatchList={()=>{
                        setActiveWatchlist(null); 
                        props.updateWatchLists(watchlists);
                        console.log("*** closeWatchList", watchlists);
                    }}
                    filterOptions={props.filterOptions}
                /> 
            }

        </Drawer>
    </>

}

/*

 
*/