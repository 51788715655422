import React from 'react';
import {Message} from 'semantic-ui-react';


export default (props)=>{
    return(
        <Message color="orange">
            <Message.Header>Prequalification Alert</Message.Header>
            <p>{props.message}</p>
        </Message>
    )
}