import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import styled from 'styled-components';
import Columns from './Columns';
import RefreshIcon from './RefreshIcon/RefreshIcon';

const DropdownWrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
    text-align:left;
    padding:2em 1em 0 1em;
`
const DropdownSpacer = styled.div`
    flex-grow:1;
`




const options = [
    { key: 1, text: 'Newest First', value: "createdDate_DESC" },
    { key: 2, text: 'Oldest First', value: "createdDate_ASC" },
    { key: 5, text: 'Make A-Z', value: "make_ASC" },
    { key: 6, text: 'Make Z-A', value: "make_DESC" },
    { key: 7, text: 'Model A-Z', value: "model_ASC" },
    { key: 8, text: 'Model Z-A', value: "model_DESC" },
    { key: 9, text: 'CR Score Asc', value: "CRScore_ASC" },
    { key: 10, text: 'CR Score Desc', value: "CRScore_DESC" },
    {key:11, text:'Year Asc', value:"year_ASC"},
    {key:12, text:'Year Desc', value:"year_DESC"},
    {key:14, text: 'BuyNow Under MMR', value:"buyNowToMMR_ASC" },
    {key: 13, text: 'BuyNow Over MMR', value: "buyNowToMMR_DESC"},
    {key:15, text: 'Bid Under MMR', value:"currentBidToMMR_ASC" },
    {key: 16, text: 'Bid Over MMR', value: "currentBidToMMR_DESC"},
    {key: 17, text: 'End Date Desc', value: "auctionEndDate_DESC"},
    {key: 18, text: 'End Date Asc', value: "auctionEndDate_ASC"},
    {key: 19, text: 'Seller Asc', value: "sellerName_ASC"},
    {key: 20, text: 'Seller Desc', value: "sellerName_DESC"},
    {key:21, text: 'Bid Under BlkBk', value:"CurrentBidToBlackBook_ASC" },
    {key: 22, text: 'Bid Over BlkBk', value: "CurrentBidToBlackBook_DESC"},
    {key:23, text: 'Buy Now Under BlkBk', value:"BuyNowToBlackBook_ASC" },
    {key: 24, text: 'Buy Now Over BlkBk', value: "BuyNowBidToBlackBook_DESC"}
]


  
  const DropdownExampleClearable = (props) => {
    return <DropdownWrapper>
        <DropdownSpacer />
        <RefreshIcon 
            sorters={props.currentValue} 
            filters={props.filters} 
            lastRefresh={props.lastRefresh} 
            setRefresher={props.setRefresher}
            channel={props.channel}
        />
        <Columns columns={props.columns} setColumns={props.setColumns}/>
        <Dropdown  
        options={options} 
        value={props.currentValue}
        placeholder="Sort Listings"
        onChange={props.onChange}
        selection 
        clearable
        floating
        multiple
        />
    </DropdownWrapper>
  }
  
  export default DropdownExampleClearable