import React, {useState, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import WatchlistForm from '../../../filter/FilterContainer/FilterNavigation/FilterNavigation';
import WatchlistName from './WatchlistName/WatchlistName';
import update from 'immutability-helper';
import Preferences from '../../../../lib/Preferences';
import styled from 'styled-components';
import WatchlistStore from '../../../../services/WatchlistStore';
import BooleanFilter from '../../../filter/BooleanFilter/BooleanFilter';

const Spacer = styled.div`
    padding:2em;
`
const Link = styled.a`
    text-decoration:underline;
`

export default (props)=>{
    const [w, setW] = useState(props.activeWatchlist)
    const [filters, updateFilters] = useState(props.activeWatchlist.filters);
    const [watchlistName, setWatchlistName] = useState(props.activeWatchlist.name);
    const [notFlag, setNotFlag] = useState(props.activeWatchlist.notification_flag);

    const deleteWatchlist = ()=>{
        const index = props.watchlists.findIndex(item => item["id"] === w.id);
        props.watchlists.splice(index, 1);

        props.updater(props.watchlists);
        WatchlistStore.delete(w.id);

        props.closeWatchList();
    }
    
    const saveWatchlist = ()=> {
        props.activeWatchlist["name"] = watchlistName;
        props.activeWatchlist["filters"] = filters;
        props.activeWatchlist["notification_flag"] = notFlag;
        const index = props.watchlists.findIndex(item => item["id"] === props.activeWatchlist.id);
        WatchlistStore.update(props.activeWatchlist);

        props.watchlists[index] = props.activeWatchlist;

        props.updater(props.watchlists);

        props.closeWatchList();
    }

    useEffect(
        ()=>{
            console.log("*** active watchlist", props.activeWatchlist);
            setW(props.activeWatchlist);
            updateFilters(props.activeWatchlist.filters)
        },
        [props.watchlists, props.activeWatchlist]
    )

    const newFiltersCallback = (newFilters)=> {
        console.log("filters updated", newFilters);
        updateFilters(newFilters);
    }

    return <>
        <WatchlistForm 
            filters={{list:filters, updater: newFiltersCallback}} 
            open={true}
            filterOptions={props.filterOptions}
        > 
            <WatchlistName 
                name={watchlistName} 
                updater={setWatchlistName}
            />
        </WatchlistForm>

        <BooleanFilter 
          label=" Notify me if new qualified vehicle is added"
          name="notification"
          checkboxText="Enable push notifications"
          isChecked={notFlag ==1 ? true : false}
          updater={(name, value)=>{
              if (value) {
                  setNotFlag(1);
              } else {
                setNotFlag(0);
              }
          }}
        />

        <Spacer>
            <Button primary onClick={saveWatchlist}>Save Watchlist</Button> <Button color="red" onClick={deleteWatchlist}>Delete this Watchlist</Button>
            <br/><br/>
            <Link onClick={props.closeWatchList}>&lt; Back</Link>
        </Spacer>

    </>
}