import Auth from '../Auth';
import axios from 'axios';

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT || "";
const PREVIEW_API_ENDPOINT_URL = process.env.REACT_APP_PREVIEW_API_ENDPOINT || "";

export default class VehicleManager {

    constructor(options){
        this.updater = ()=>{};
        for(var key in options){
            this[key] = options[key]
        }
    }

    utcToTZ(here, utc) {
        //since we know OLO is always behind UTC we know if local hr is greater its tomorrow in UTC
        if(here > utc) return (24-here)+utc; 
        return utc-here;
    }

    getVehicles(conditions, channel){
        var params = {};
        Object.keys(conditions.filters).map((item)=>{

            

            params[item] = (conditions.filters[item].type === "list") ? conditions.filters[item].acceptedValues.join(",") : conditions.filters[item].value

            if(!params[item]) delete params[item];
            
            //TODO: REFACTOR TO HANDLE THIS AGE WINDOW GRACEFULLY AND REMOVE REPEATED CODE
            if(item === 'ageOffset' && conditions.filters[item].value){
                var dateOffset = 24*60*60*1000*conditions.filters[item].value;
                var newDate = new Date(Date.now()+dateOffset);
                params['ageOffset'] = newDate;
            }

            if(item === 'auctionEndMax' && conditions.filters[item].value && !params['auctionEndDate_max']){
                var d = new Date();
                var dateOffset = 24*60*60*1000*conditions.filters[item].value;
                dateOffset = dateOffset - (this.utcToTZ(d.getHours(), d.getUTCHours()) * 60*60*1000);
                var newDate = new Date(Date.now()+dateOffset);
                params['auctionEndDate_max'] = newDate;
            }

            return "";
        })
        params.sortBy = conditions.sorter.join(",") || "createdDate_DESC";
        params['limit'] = params['limit'] || '50';
        
        if(!params['auctionEndDate_min'] && !params['showPastAuctionEndDate']){
            var mdate = new Date()
            //timezone adjustment required for auction end dates
            var mdateOffset = -1 * (this.utcToTZ(mdate.getHours(), mdate.getUTCHours()) * 60*60*1000);
            var newMDate = new Date(Date.now()+mdateOffset);
            params['auctionEndDate_min'] = newMDate;
        }

      
        if(params['ageOffset']){
            if(params['createdDate_min']){
                var maxTime = Math.max(params['createdDate_min'].getTime(), params['ageOffset'].getTime());
                params['createdDate_min'] = new Date(maxTime);
            }
            else{
                params['createdDate_min'] = new Date(params['ageOffset'].getTime());
            }
            delete params['ageOffset'];
        }

        
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listings?params="+JSON.stringify(params) +"&channel="+channel,
            method:"GET",
            headers:{}
        })
    }

    getVehicleDetail(vin, segment=false, channel="OVE", eventDate = null){

        var endpoint = API_ENDPOINT_URL +"/listing/"+vin;
        endpoint += (segment) ? '?action='+segment+'&channel='+channel : '?channel='+channel;
        endpoint += (eventDate) ? '&eventDate='+eventDate : ''
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    getAuctionVehicles(vin){
        var endpoint = API_ENDPOINT_URL +"/listing/"+vin;
        endpoint += '?action=auctionVehicles';
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }    

    getPushSubscriptions(){
        var endpoint = API_ENDPOINT_URL +"/pushSubscriptions";
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }
    
    updatePushSubscription(subscriptionData) {
        var endpoint = API_ENDPOINT_URL +"/pushSubscriptions";
        return this.callApiEndpoint({
            url: endpoint,
            method: "POST",
            headers:{},
            data: subscriptionData
        })
    }

    getStaticVehicleDetail(vin, segment=false, channel='SIMULCAST'){

        var endpoint = API_ENDPOINT_URL +"/listing/"+vin;
        endpoint += (segment) ? '?action='+segment+'&channel='+channel : '?channel='+channel;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    getWatchlists(){
        var endpoint = API_ENDPOINT_URL +"/watchlist";
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    addWatchlist(data) {
        var endpoint = API_ENDPOINT_URL +"/watchlist";
        return this.callApiEndpoint({
            url: endpoint,
            method: "POST",
            headers:{},
            data: data
        })
    }

    updateWatchlist(data) {
        var endpoint = API_ENDPOINT_URL +"/watchlist";
        return this.callApiEndpoint({
            url: endpoint,
            method: "PUT",
            headers:{},
            data: data
        })
    }

    deleteWatchlist(data) {
        var endpoint = API_ENDPOINT_URL +"/watchlist";
        return this.callApiEndpoint({
            url: endpoint,
            method: "DELETE",
            headers:{},
            data: data
        })
    }
    
    markAsRead(vin, channel){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listing/"+vin+"/activity?channel="+channel,
            method: "POST",
            headers:{},
            data: {
                read: true
            }
        })
    }

    markAsClicked(vin, channel){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listing/"+vin+"/activity?channel="+channel,
            method: "POST",
            headers:{},
            data: {
                clicked: true
            }
        })
    }

    markAsDisqualified(data){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL + "/listing/"+data.vin+"/activity",
            method: "POST",
            headers:{},
            data: {
                dqVIN: true,
                dqGlobal: (data.dqGlobal) ? true : false,
                dqReason: data.dqReason
            }  
        })
    }

    markAsHidden(data){
        var d = new Date();
        var nextMonth = d.setTime(d.getTime() + (30*24*60*60*1000));
        var hDate = new Date(nextMonth);
        var hideDate = hDate.getFullYear() +"-";
        hideDate += (hDate.getMonth() > 8) ? hDate.getMonth()+1 : "0"+(hDate.getMonth()+1);   
        hideDate += "-"     
        hideDate += (hDate.getDate() > 9) ? hDate.getDate() : "0"+(hDate.getDate());
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listing/"+data.vin+"/activity",
            method: "POST",
            headers:{},
            data: {
                hidden: true,
                hideReason: data.hideReason,
                hiddenUntil: hideDate
            }
        })
    }

    markAsFollow(data, channel){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listing/"+data.vin+"/activity?channel="+channel,
            method: "POST",
            headers:{},
            data: {
                followUp: data.followUp
            }
        })        
    }

    getFilters(){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/filters",
            method:"GET",
            headers:{}
        })
    }

    getNetDemand(vin){
        return this.callApiEndpoint({
            url: PREVIEW_API_ENDPOINT_URL +"/inventory/net_demand/"+vin,
            method:"GET",
            headers:{}
        })
    }

    async callApiEndpoint(params) {
        var loginRequest = {scopes: ["Files.Read", "openid"] };
        var token;
        try{
            var accounts = Auth.getAllAccounts();
            var useAccount = false;
            accounts = (Array.isArray(accounts)) ? accounts : []; 
            for(var i = 0; i < accounts.length; i++ ) {
                if(accounts[i].homeAccountId){
                    useAccount = accounts[i];
                    break;
                }
            }
            if(useAccount){
                token = await Auth.acquireTokenSilent({account: useAccount}); 
            }
            token = (token) || await Auth.loginPopup(loginRequest);
            params.headers = params.headers || {};
            params.headers['Authorization'] = 'Bearer '+ token.idToken;
            params.headers['Content-Type'] = 'application/json';
            return axios(params);
        }catch(err){
           console.log(err);
        }
    }

}
