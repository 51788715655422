import React from 'react'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

const StyledHeadline = styled.h1`
    margin: 1em 0;
    width:360px;
    overflow-x:hidden;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start
`

const HeadlineIcon = styled.span`
    display:block;
    width:40px;
    font-size:2em;
    margin:0;
    text-align:center;
    cursor:pointer;
    &.small {
        font-size:1rem;
    }
`
const HeadlineText =styled.span`
    display:block;
    flex-grow:1;
    font-size:1.2em;
    font-weight: 700;
    text-align:left;
`



export default ({updateFilterState}) =>{
   return <StyledHeadline onClick={()=>{updateFilterState()}} >
       <HeadlineIcon><Icon name="filter" size="small" fitted={true}/></HeadlineIcon>
       <HeadlineText>FILTER</HeadlineText>
       <HeadlineIcon className="small"><Icon name="close" size="small" fitted={true} /></HeadlineIcon>
    </StyledHeadline>
}