import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Accordion} from 'semantic-ui-react';
import Damages from '../../TableRowDetailDamages/TableRowDetailDamages';
import AdesaDamages from '../../TableRowDetailDamages/TableRowDetailDamagesAdesa'
import Tires from '../../TableRowDetailTires/TableRowDetailTires';
import Keys from '../../TableRowDetailKeys/TableRowDetailKeys';
import Preferences from '../../../../../../lib/Preferences';
import Loading from '../../../../../elements/Loading';

const DetailLabel = styled.span`
    font-size:0.8em;
    text-align:left;
    text-transform: uppercase;
    font-weight:bold;
    margin:0;
    color:#FFFFFF;
    display:inline-block;
    padding:.5em;
`

export default (props)=>{
    var pref = new Preferences();
    pref.loadPreferences();
    var accordions = (Array.isArray(pref.preferences['rightAccordion'])) ? pref.preferences['rightAccordion'] : [0,1,2];
    pref.preferences['rightAccordion'] = accordions;
    const [accordionState, setAccordionState ] = useState(accordions);

    const titleClick = (event, data)=>{
        if(data.active){
            var filteredAccordion = accordionState.filter(i=>i !== data.index);
            setAccordionState([...filteredAccordion])
        }
        else{
            if(!accordionState.includes(data.index)) setAccordionState([...accordionState,data.index]);
        }
    }

    const damagesComponent = (channel)=>{
        if(channel == 'DealerBlock') return <AdesaDamages vin={props.vehicle.vin} channel={props.channel}/>
        return <Damages vin={props.vehicle.vin} channel={props.channel}/>
    }

    useEffect(()=>{
        pref.updatePreference('rightAccordion', accordionState)
    },[accordionState])

    const panels = [
        {
            key:"damages",
            title:{
                className: "navyLabel",
                content:<DetailLabel>DAMAGES</DetailLabel>
            },
            content:{content: damagesComponent(props.channel)},
            onTitleClick: titleClick
        },

        {
            key:"tires",
            title:{
                className: "navyLabel",
                content:<DetailLabel>TIRES</DetailLabel>
            },
            content:{content:<Tires vin={props.vehicle.vin} channel={props.channel}/>},
            onTitleClick: titleClick
        },

        {
            key:"keys",
            title:{
                className: "navyLabel",
                content:<DetailLabel>KEYS</DetailLabel>
            },
            content:{content: <Keys vin={props.vehicle.vin} channel={props.channel}/>},
            onTitleClick: titleClick
        }
        
    ]

    const returnLogic = ()=>{
        if(props.loading) return <Loading />
        return <Accordion activeIndex={accordionState} panels={panels} vertical="true" exclusive={false}/>
    }
    
    return <>
         {returnLogic()}
    </>
}