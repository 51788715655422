import React from 'react';
import styled from 'styled-components';
import StyledNumber from '../../../StyledNumber';


const Column = styled.div`
    width:50%;
    min-width:300px;
`

const FlexWrapper = styled.div`
    display: flex;
    align-items:stretch;
    &.top {
        align-items:top;
    }
`

const Label = styled.div`
    width:50%;
    background:#F2F2F2;
    padding:1em;
    font-weight:bold;
`

const Value = styled.div`
    width:50%;
    flex-grow:1;
    background:#FFFFFF;
    padding:1em;
`


export default ({vehicle, channel})=>{
    const indexMap = {
        "OVE": [
            {value:'engine', label: 'Engine'},
            {value:'extendedModel', label:'Extended Model'},
            {value: 'exteriorColor', label: 'Exterior Color'},
            {value:'fuelType', label: 'Fuel Type'},
            {value:'hasAirConditioning', label: 'Has A/C'},
            {value:'interiorType', label: 'Interior Type'},
            {value:'make', label: 'Make'},
            {value:'model', label: 'Model'},
            {value:'mileage', label: 'Mileage', output:(v)=>{return <StyledNumber value={v['mileage']}/>} },
            {value:'odometerUnits', label: 'Odometer Units'},
            {value:'roof', label: 'Roof'},
            {value:'transmission', label: 'Transmission'},
            {value:'trim', label: 'Trim'},
            {value:'typeCode', label: 'Type Code'},
            {value:'vin', label: 'VIN'},
            {value:'year', label: 'Year'}
        ],
        "DealerBlock":[
            {value:'engine', label: 'Engine'},
            {value:'extendedModel', label:'Extended Model'},
            {value: 'exteriorColor', label: 'Exterior Color'},
            {value:'fuelType', label: 'Fuel Type'},
            {value:'make', label: 'Make'},
            {value:'model', label: 'Model'},
            {value:'mileage', label: 'Mileage', output:(v)=>{return <StyledNumber value={v['mileage']}/>} },
            {value:'transmission', label: 'Transmission'},
            {value:'trim', label: 'Trim'},
            {value:'vin', label: 'VIN'},
            {value:'year', label: 'Year'}
        ],
        "BMW_GROUP_DIRECT": [
            {value:'engine', label: 'Engine'},
            {value:'extendedModel', label:'Extended Model'},
            {value: 'exteriorColor', label: 'Exterior Color'},
            {value:'fuelType', label: 'Fuel Type'},
            {value:'hasAirConditioning', label: 'Has A/C'},
            {value:'interiorType', label: 'Interior Type'},
            {value:'make', label: 'Make'},
            {value:'model', label: 'Model'},
            {value:'mileage', label: 'Mileage', output:(v)=>{return <StyledNumber value={v['mileage']}/>} },
            {value:'odometerUnits', label: 'Odometer Units'},
            {value:'roof', label: 'Roof'},
            {value:'transmission', label: 'Transmission'},
            {value:'trim', label: 'Trim'},
            {value:'typeCode', label: 'Type Code'},
            {value:'vin', label: 'VIN'},
            {value:'year', label: 'Year'}
        ],
    }
    const indices = indexMap[channel]
    
    var leftRow = indices.splice(0, Math.ceil(indices.length / 2));

    return(
        <FlexWrapper>
            <Column>
                {leftRow.map( (item)=>{
                    return <FlexWrapper key={item.value}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
            <Column>
                {indices.map( (item)=>{
                    return <FlexWrapper key={item.value}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
        </FlexWrapper>
    )
}