

export default (preferences, defaultColumns, channel)=>{


    var preferences_version = localStorage.getItem("pref_version")

    if(!preferences['columns'] || (preferences['columns'] && !preferences['columns']['BMW_GROUP_DIRECT']) ){
        preferences = {
            'columns':{
                'OVE': preferences['columns'],
                'DealerBlock':{},
                "BMW_GROUP_DIRECT":{}
            }
        }
    }

    var prefKeys = (preferences['columns'] && preferences['columns'][channel]) ? Object.keys(preferences['columns'][channel]) : [];

    // 7-12-2021: Adding a local storage item for tracking changes to the default column data
    // This allows us to push any updated column setups out to all users so everyone sees the update
    // Once the defaults are loaded, customers can adjust the columns and their changes will be saved/used as the default
    if(!preferences_version || preferences_version < preferences['columns']['version']){
        localStorage.setItem("pref_version", preferences['columns']['version']);
    }
    else{
        if(Array.isArray(prefKeys) && prefKeys.length > 0){
            Object.keys(preferences['columns'][channel]).map((k)=>{
                defaultColumns[channel][k] = preferences['columns'][channel][k];
            })   
        }
    
    }

    return defaultColumns
}