import React from 'react';
import styled from 'styled-components';
import {Message} from 'semantic-ui-react';

const MessageWrapper = styled.div`
    padding:1em 0;
`

export default ({announcements})=>{
    return <MessageWrapper>
        <Message color="blue" size="large">
            <Message.Header>Announcements</Message.Header>
            {announcements}
        </Message>
    </MessageWrapper>
}