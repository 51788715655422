import React from 'react';
import styled from 'styled-components';

const Column = styled.div`
    width:50%;
    min-width:300px;
`

const FlexWrapper = styled.div`
    display: flex;
    align-items:stretch;
    &.top {
        align-items:top;
    }
`

const Label = styled.div`
    width:50%;
    background:#F2F2F2;
    padding:1em;
    font-weight:bold;
`

const Value = styled.div`
    width:50%;
    flex-grow:1;
    background:#FFFFFF;
    padding:1em;
`


export default ({vehicle, channel})=>{

    const indexMap = {
        "OVE":[
            {value:'CRScore', label: 'CR Score'},
            {value: 'certified', label: 'Certified'},
            {value: 'frameDamage', label: 'Frame Damage', output: (vehicle)=>{
            var damage = false;
            if(vehicle.frameDamage) damage = true;
            if(vehicle.structuralDamage) damage = true;
            if(vehicle.structuralAlteration) damage = true;
            return (damage) ? "Yes" : "No"; 
            }},
            {value:'grade', label: 'Grade'},
            {value:'salvage', label: 'Salvage'},
            {value:'titleStatus', label: 'Title Status'}
        ],
        "DealerBlock":[
            {value:'CRScore', label: 'CR Score'},
            {value: 'frameDamage', label: 'Frame Damage', output: (vehicle)=>{
                var damage = false;
                if(vehicle.frameDamage) damage = true;
                if(vehicle.structuralDamage) damage = true;
                if(vehicle.structuralAlteration) damage = true;
                return (damage) ? "Yes" : "No"; 
            }},
            {value:'grade', label: 'Grade'}
        ],
        "BMW_GROUP_DIRECT":[
            {value:'CRScore', label: 'CR Score'},
            {value: 'certified', label: 'Certified'},
            {value: 'frameDamage', label: 'Frame Damage', output: (vehicle)=>{
            var damage = false;
            if(vehicle.frameDamage) damage = true;
            if(vehicle.structuralDamage) damage = true;
            if(vehicle.structuralAlteration) damage = true;
            return (damage) ? "Yes" : "No"; 
            }},
            {value:'grade', label: 'Grade'},
            {value:'salvage', label: 'Salvage'},
            {value:'titleStatus', label: 'Title Status'}
        ],
    }
    
    const indices = indexMap[channel]
    
    var leftRow = indices.splice(0, Math.ceil(indices.length / 2));

    return(
        <FlexWrapper>
            <Column>
                {leftRow.map( (item)=>{
                    return <FlexWrapper key={item.value}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
            <Column>
                {indices.map( (item)=>{
                    return <FlexWrapper key={item.value}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
    </FlexWrapper>
    )
}