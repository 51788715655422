import React from 'react';
import styled from 'styled-components';
import {Loader} from 'semantic-ui-react';

const LoadingWrapper = styled.div`
    padding:3em;
    text-align:center;
`

export default (props)=>{
    return <LoadingWrapper><Loader inline active/></LoadingWrapper>
}