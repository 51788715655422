import React, {useState, useEffect} from 'react';
import Loading from '../Loading/Loading';
import StyledMain from './StyledMain';
import Table from './table/Table2';
import Sort from './sort/Sort';
import ListingConfig from '../../config/ListingConfig';
import Preferences from '../../lib/Preferences';
import styled from 'styled-components';
import update from 'immutability-helper';
import MainPreferences from './MainPreferences';

const LWrap = styled.div`
    margin:2em;
    padding:2em;
    background:#FFFFFF;
`

export default function Main({vehicles, channel}){
    var Pref = new Preferences();
    Pref.loadPreferences();
    var defaultColumns = ListingConfig.columns;
    
    defaultColumns = MainPreferences(Pref.preferences, defaultColumns, channel)
    const [columns, setColumns] = useState(defaultColumns[channel]);

    useEffect(()=>{
        var p = new Preferences();
        p.loadPreferences();
        if(p.preferences['columns'] && Array.isArray(p.preferences['columns'][channel])){
            setColumns(p.preferences['columns'][channel]);
        }
        else{
            p.updatePreference('columns', defaultColumns)
        }
    },[]);

    useEffect(()=>{
        var p = new Preferences();
        p.loadPreferences();

        if(p.preferences['columns']){
            if(JSON.stringify(columns) !== JSON.stringify(p.preferences['columns'][channel])){
                p.preferences['columns'][channel] = columns
                p.updatePreference('columns', p.preferences['columns']);
            }
        }

    }, [columns])

    return <StyledMain>
        <Sort 
            currentValue={vehicles.sorters.value} 
            onChange={
                (e,{value}) =>{
                    vehicles.sorters.updater(value);
                    var f = {};
                    f['offset'] = {$set: {
                        type:'range',
                        field: 'offset',
                        value: 0
                      }};   
                    var newFilter = update(vehicles.filters.list, f);
                    vehicles.filters.updater(newFilter);
                }
            }
            columns={columns}
            setColumns={setColumns}
            filters={vehicles.filters.list}
            lastRefresh={vehicles.lastRefresh}
            setRefresher={vehicles.setRefresher}
            channel={channel}
        />

        {vehicles.loading ? <LWrap><Loading /></LWrap> : <Table vehicles={vehicles} columns={columns} channel={channel} />}
    </StyledMain>
}