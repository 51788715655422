import React, {useState, useEffect} from 'react';
import {Table, Modal, Image} from 'semantic-ui-react';
import styled from 'styled-components';
import VehicleManager from '../../../../../services/vehicles/VehicleManager'

const DamagesWrapper = styled.div`
    padding:1em 0;
    flex-grow:1;
    width:100%;
    h2 {
        font-size:1.1em;
        font-weight:700;
    }
`

export default ({vin, channel}) =>{
    const [damages, setDamages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(false)

    useEffect( ()=>{
        var ignore = false;
        if(!vin) return;
        setLoading(true)
        var v = new VehicleManager();
        v.getVehicleDetail(vin, 'damages', channel).then( (result)=>{
            if(!ignore) setDamages(result.data['damages']);
            setLoading(false)
        }).catch(()=>{setLoading(false)})
        return ()=>{ignore = true;}
    },[vin, setLoading])

    const outputItem = (d)=>{
        if(d.photoUrl){
            return <a onClick={()=>setImage(d.photoUrl)}>{d.area}</a>
        }
        else{
            return <>{d.area}</>
        }
    }

    return <DamagesWrapper>
        
        <Table celled>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Area</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Estimate</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {damages && damages.map( (damage, key)=>{
                return <Table.Row key={key}>
                    <Table.Cell>{outputItem(damage)}</Table.Cell>
                    <Table.Cell>{damage.type}</Table.Cell>
                    <Table.Cell>{damage.description}</Table.Cell>
                    <Table.Cell>{damage.estimate}</Table.Cell>
                </Table.Row>
            } )}
            {loading && <Table.Row><Table.Cell colSpan="4">Loading damages...</Table.Cell></Table.Row>}
            </Table.Body>
        </Table>
        {image && <Modal size="small" onClose={()=>setImage(false)} open={image} closeIcon>
            <Modal.Header>Damage Detail</Modal.Header>
            <Modal.Content>
                <center><Image size='large' src={image} /></center>
            </Modal.Content>
        </Modal>}
    </DamagesWrapper>

}