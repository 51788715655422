import VehicleManager from '../../services/vehicles/VehicleManager';



var calcTitle = (wl)=>{
    if (!wl) return;
    var count = 0;
    for(var i = 0; i < wl.length ; i++){
        count += wl[i]['newListings'] || 0;
    }

    document.title = (count > 0) ? "Static Auction (" +count+")" : "Static Auction"; 
}

export default (watchLists, updateWatchLists, channel)=>{
    return ()=>{
        var ignore = false;
        var newWatchList = [];
        var inProgress = false;


        const pollForNew =  async (watchListItem)=>{
            if(ignore) return;
            var v = new VehicleManager();
            var w = {...watchListItem};
            var f = {...w.filters}
            var lastChk = w['lastChecked'] || new Date();
            lastChk = (typeof lastChk === "object") ? lastChk : new Date(lastChk);
            f['createdDate_min'] = {value: lastChk, type:"range"}  ;
            f['limit'] = {value:1, type:"range"}; //keeps the payload small
            try{
                var data = await v.getVehicles({sorter:["createdDate_DESC"], filters:f}, channel);
                w.newListings = data.data.total_count;
                newWatchList.push(w);
            }catch(err){
                console.log(err);
                newWatchList.push(w);
            }
        }
        

        var pollingFunc = async ()=>{
            if(inProgress || !watchLists) return;
            inProgress = true;
            newWatchList = [];
            for(var i = 0; i < watchLists.length; i++){
                await pollForNew(watchLists[i]);
            }
            if(!ignore){
                updateWatchLists(newWatchList);
                calcTitle(newWatchList);
            }
            inProgress = false;
        }
        var immediateCheck = setTimeout(pollingFunc, 30000);
        var watchListPoller = setInterval(pollingFunc, 300000)
        calcTitle(watchLists);
        return ()=>{
            ignore = true;
            clearInterval(watchListPoller);
            clearTimeout(immediateCheck);
        }
    }
}