import React from 'react';
import styled from 'styled-components';
import StyledMoney from '../../../StyledMoney';
//import ShippingConfig from '../../../../../../config/ShippingConfig'

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const DetailLabel = styled.div`
    font-size:0.8em;
    text-align:center;
    text-transform: uppercase;
    font-weight:bold;
    padding:1em;
    margin:2em 0 0 0;
    background:#303960;
    color:#FFFFFF;
`
const DetailItem = styled.div`
    width:20%;
`

const DetailValue = styled.div`
    padding:2em 0;
    font-size:1.1em;
    text-align:center;
`

export default ({vehicle})=>{
   // const {locationZip} = vehicle;
   // var zipStr = (locationZip && locationZip !== "NULL") ? (locationZip.length < 5) ? "0"+locationZip : locationZip.substring(0,5) : "0";
    return <Wrapper>
        <DetailItem>
            <DetailLabel>Buy Now</DetailLabel>
            <DetailValue><StyledMoney value={vehicle.buyNowPrice}/></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>Current Bid</DetailLabel>
            <DetailValue><StyledMoney value={vehicle.currentBidPrice}/></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>MMR</DetailLabel>
            <DetailValue><StyledMoney value={vehicle.adjMmr}/></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>Black Book</DetailLabel>
            <DetailValue><StyledMoney value={vehicle.BlackBook}/></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>Shipping Price</DetailLabel>
            <DetailValue><StyledMoney value={vehicle.ShippingCost} /></DetailValue>
        </DetailItem>
    </Wrapper>
}