import styled from 'styled-components'

export default styled.div`
  width: 40px;
  position: fixed;
  height:100%;
  padding:90px 0 25px 0;
  overflow-y:scroll;
  z-index:10;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  &.open {
    width:360px;
    background:#FFFFFF;
    border-right: 1px solid #d4d4d4;

  }
`