import VehicleManager from './vehicles/VehicleManager';

const WatchlistStore = {
    data: [],
    dataMap: {},
    loaded: false,

    async getAll(cb) {
        var self = this;
        
        if (this.loaded) {
            cb(self.data);
            return;
        }


        var v = new VehicleManager();
        v.getWatchlists().then( (data)=>{
            //console.log("watchlist data", data.data.watchlists);
            self.data = data.data.watchlists;
           // console.log("watchlist self.data", self.data);
            self.loaded = true;
            self.dataMap = self.data.reduce(function(obj, item){
                item["newListings"] = 0;
                item["lastChecked"] = new Date();
                item["filters"] = JSON.parse(item["filters"]);
                obj[item["id"].toString()] = item;
                return obj;
            }, {});
            cb(true, self.data)
        }).catch( (e)=>{
            console.log(" error getting watchlist data");
             console.log(e);
             cb(false, self.data)
        })

    },

    async add(wl) {
        var v = new VehicleManager();
        var self = this;
        v.addWatchlist(wl).then( (data)=>{
            var newData = data.data.data;
            newData["newListings"] = 0;
            newData["lastChecked"] = new Date();

            self.data.push(newData);
            wl.id = newData.id;
            return newData;
        }).catch( (e)=>{
             console.log(e);
             return wl;
        })
    },

    update(wl) {
        var self = this;
        this.dataMap[wl["id"].toString()] = wl;
        var v = new VehicleManager();
        wl["watchlist_id"] = wl["id"];
      //  console.log("*** before watchlist update", wl);
        v.updateWatchlist(wl).then( (data)=>{
           // console.log("watchlist data", data);
            data["newListings"] = wl["newListings"];
            data["lastChecked"] = wl["lastChecked"];
            //self.data.push(data.data);
            return data.data;
        }).catch( (e)=>{
             console.log(e);
             return wl;
        })
    },

    delete(id) {
        //delete from local cache
        const index = this.data.findIndex(item => item["id"] === id);
        this.data.splice(index, 1);
        delete this.dataMap[id.toString()];

        var v = new VehicleManager();
        const payload = {"watchlist_id": id};

        v.deleteWatchlist(payload).then( (data)=>{
        }).catch( (e)=>{
             console.log(e);
        })
   }
}

export default WatchlistStore;