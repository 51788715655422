import React, {useState} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import Headline from './Headline/Headline';
import Controls from './Controls/Controls';
import DQModal from './DQModal/DQModal';

const HeadlineWrapper = styled.div`
    display:flex;
    border-bottom:1px solid #d4d4d4;
    padding: 1em 0;
    align-items: center;
`



export default ({vehicle, updater, disqualifier, channel, markAsFollowed}) => {

    const [dqModal, setDQModal] = useState(false)

    return <HeadlineWrapper>
        <Button onClick={(e)=>{e.stopPropagation();updater()}}><Icon name="close" fitted/></Button>
        <Headline vehicle={vehicle} />
        <Controls setDQModal={setDQModal} vehicle={vehicle} channel={channel} markAsFollowed={markAsFollowed} />
        <DQModal dqModal={dqModal} setDQModal={setDQModal} vin={vehicle.vin} disqualifier={disqualifier} />
    </HeadlineWrapper>
}