import React, {useState} from 'react';
import {Button, Icon, Modal, Header, TextArea, Form} from 'semantic-ui-react';
import DQModalForm from './DQModalForm/DQModalForm';
import DQModalFormActions from './DQModalFormActions/DQModalFormActions';
import DQModalComplete from './DQModalComplete/DQModalComplete';
import DQModalCompleteActions from './DQModalCompleteActions/DQModalCompleteActions';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';


export default ({dqModal, setDQModal, vin, disqualifier})=>{

    const [comment, setComment] = useState("");
    const [showForm, setShowForm] = useState(true);

    const captureComment = (e,{value})=>{
        setComment(value);
    }

    const submitDQ = ()=>{
        var v = new VehicleManager();
        v.markAsDisqualified({
            vin: vin,
            dqGlobal: true,
            dqReason: comment
        })
        setShowForm(false);
    }

    const closeAndRemove = ()=>{
        setDQModal(false);
        disqualifier();
    }

    return(
        <Modal open={dqModal} onClose={()=>{setDQModal(false)}}>
            {showForm && <DQModalForm captureComment={captureComment} vin={vin} />}
            {!showForm && <DQModalComplete vin={vin}/>}
            {showForm && <DQModalFormActions submitDQ={submitDQ}/>}
            {!showForm && <DQModalCompleteActions closeAndRemove={closeAndRemove}/>}
        </Modal>
    )
}