import React, {useState, useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components';


const FilterLabel = styled.h2`
    font-size:1em;
    margin:3em 0 0.8em 0;
    font-weight:700;
    color:#6b6b6b;
    text-transform:uppercase;
    padding:0.5em 0;
`

const Reload = styled.a`
  display:block;
  text-decoration:underline;
  color:blue;
  font-size:0.8em;
  padding:1em 0 0 0;
  text-align:right;
`

const Filter = (props) => {
  var defaults = props.currentState ? props.currentState.value : null ;
  const [dropdownValue, setValue] = useState(defaults);

  useEffect(
    ()=>{
      var defaults = props.currentState ? props.currentState.value : null;
      setValue(defaults);
    },
    [props.currentState]
  )

  const change = (e, {value})=>{
    setValue(value);    
    props.handleOnChange(e, {value: value});
  }

  return <>
    <FilterLabel>{props.label}</FilterLabel>
    <Dropdown
        clearable
        fluid
        selection
        options={props.options}
        placeholder={`Select ${props.label}`}
        onChange={change}
        value={dropdownValue}
        noResultsMessage={props.noResultsMessage}
    />
  </>
}

export default Filter