import React, {useState} from 'react';
import {Input} from 'semantic-ui-react';



export default (props)=>{
    const [name, setName] = useState(props.name);


    return <Input 
                placeholder="Enter Watchlist Name" 
                value={name} 
                onChange={(e)=>{setName(e.target.value)}}
                onBlur={()=>{props.updater(name)}}
                size="large"
            />

}