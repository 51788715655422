import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.div`
    font-size:1em;
    &.inline{
        display:inline-block;
    }
`
function numberWithCommas(x) {
    return x ? "$"+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
}

export default ({value, inline}) =>{
    return <StyledItem className={inline ? "inline":""}>{numberWithCommas(value)}</StyledItem>
}
 