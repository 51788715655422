import React from 'react'
import StateMap from '../../../../states';

export default ({vehicle, channel})=>{

    const outputLocation = (channel)=>{
        if(channel == 'DealerBlock'){
            return StateMap[vehicle.pickupLocationState] + ' ('+vehicle.locationZip+')';
        }
        return vehicle.pickupLocation +  ', ' + vehicle.pickupLocationState
    }

    return( <>{outputLocation(channel)}</>)
}