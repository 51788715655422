import React, {useState, useEffect} from 'react';
import {Checkbox} from 'semantic-ui-react';
import styled from 'styled-components';

const FilterLabel = styled.h2`
    font-size:1em;
    margin:3em 0 0.8em 0;
    font-weight:700;
    color:#6b6b6b;
    text-transform:uppercase;
    padding:0.5em 0;
`

export default (props)=>{

    const [checked, setChecked] = useState(props.isChecked);

    useEffect( ()=>{
        setChecked(props.isChecked);
    }, [props.isChecked])

    const toggleCheckbox = (e, data)=>{
        props.updater(props.name, !checked);
    }

    return(
        <>
            <FilterLabel>{props.label}</FilterLabel>
            <Checkbox checked={checked} label={props.checkboxText} onClick={toggleCheckbox}/>
        </> 
    )


}