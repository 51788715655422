import React, {useState} from 'react';
import styled from 'styled-components'
import {Modal, Button, Icon, Checkbox} from 'semantic-ui-react';

const TableEditor = styled.a`
    display:inline-block;
    padding:0 1em;
    font-size:1.7em;
    color:#333333;

    &:hover {
        color:purple;
        text-decoration: none;
    }
`
const ToggleItem = styled.div`
    padding:1em 0;
    font-size:0.9em;
`



export default (props)=>{
    const [columnList, updateColumnList] = useState(props.columns)
    const [modalOpen, updateModalOpen] = useState(false);

    const toggleColumns = ()=>{
        if(modalOpen) props.setColumns({...columnList});
        updateModalOpen(!modalOpen);
    }

    return <TableEditor>
            <Icon name="table" fitted onClick={toggleColumns}/>
            <Modal size="tiny" open={modalOpen} onClose={toggleColumns}>
            <Modal.Header>Customize Columns</Modal.Header>
                <Modal.Content scrolling>
                {Object.keys(columnList).map((item, key)=>{
                    return <ToggleItem key={key}>
                        <Checkbox 
                            label={columnList[item].label} 
                            checked={columnList[item].active} 
                            onChange={(e, cb)=>{
                                columnList[item].active = cb.checked;
                                updateColumnList({...columnList})
                            }} 
                            toggle
                        />
                    </ToggleItem>
                })}
                </Modal.Content>
                <Modal.Actions>
                <Button primary onClick={toggleColumns}>
                    Apply
                </Button>
                </Modal.Actions>
            </Modal>
        </TableEditor>
}