import React, {useState} from 'react';
import {Icon} from 'semantic-ui-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import VehicleManager from '../../../../services/vehicles/VehicleManager';


export default (props)=>{
    const [copied, setCopied] = useState(false);

    return <CopyToClipboard 
        text={props.vin}
        color="grey"
        onCopy={()=>{ 
            setCopied(true);
            setTimeout(()=>{setCopied(false)}, 1000);
            if(props.markAsRead){
                var v = new VehicleManager();
                v.markAsRead(props.vin);
                props.markAsRead();
            }
        }}
    >
        {copied ?  <Icon name="check" fitted/> : <Icon name="copy" fitted/> }
    </CopyToClipboard>
}
