export default class Preferences {

    constructor(){
        this.preferences = {
            watchlists:[],
            columns:[],
            rightAccordion:[0,1,2],
            leftAccordion:[0,1,2,3]
        }
    }

    loadPreferences(){
        for(var p in this.preferences){
            this.preferences[p] = JSON.parse(localStorage.getItem(p));
        }
    }

    savePreferences(){
        for(var p in this.preferences){
            localStorage.setItem(p,JSON.stringify(this.preferences[p]))
        }
    }

    updatePreference(key, value){
        this.preferences[key] = value;
        this.savePreferences();
    }

    
}