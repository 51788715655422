
export default {

    getMakes: (cars)=>{
        return Object.keys(cars).sort();
    },
    
    getModels: (cars, filters)=>{
        var models = [];
        var possibleKeys = Object.keys(cars).sort();
        var keys = [];
        if(filters['make'] && filters['make']['acceptedValues'].length > 0){
          keys = possibleKeys.filter(function(item){
            return filters['make']['acceptedValues'].includes(item);
          })
        }
        keys.forEach( (item)=>{
          models = models.concat(cars[item]);
        })
        models.sort();
        return models;
    }, 

    updateGlobalFilters: (filters, updater)=>{
        return ()=>{
          
            var updateFilters = setTimeout(()=>{updater(filters)}, 500);
            return ()=>{clearTimeout(updateFilters)};
        }
    },

    stateOptions: [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ],

    ageFilters: [
      {key: 'alltime', text:'All Listings', value:null},
      {key: 'last3hrs', text:'Last 3 Hours', value:-0.125},
      {key: 'lastday', text:'Last 24 Hours', value:-1},
      {key: 'last72', text:'Last 72 Hours', value:-3},
      {key: 'lastweek', text:'Last Week', value:-7},
      {key: 'lastmonth', text:'Last Month', value:-30}
    ],

    endDateFilters: [
      {key: 'alltime', text:'All Listings', value:null},
      {key: 'next3hrs', text:'Next 3 Hours', value:0.125},
      {key: 'next6hrs', text:'Next 6 Hours', value:0.25},
      {key: 'nextday', text:'Next 24 Hours', value:1},
      {key: 'next72', text:'Next 72 Hours', value:3},
      {key: 'nextweek', text:'Next Week', value:7},
      {key: 'nextmonth', text:'Next Month', value:30}
    ]
}