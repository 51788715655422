import React from 'react';
import TableRow from './TableRow';
import {Table} from 'semantic-ui-react';
import update from 'immutability-helper';


export default (props)=>{
    const setListingReadInState = (index)=>{
      var newList = update(props.vehicles, {$apply: (l)=>{
        l['list'][index]['read_at'] = Date.now();
        l['offset'] = l['rowOffset'];
        delete l['rowOffset'];
        return l;
      }});
      props.vehicles.updater(newList);
    }

    const setListingFollowedInState = (index, value)=>{
      var newList = update(props.vehicles, {$apply: (l)=>{
        l['list'][index]['followUp'] = (value) ? true : false;
        l['list'][index] = {...l['list'][index]}
        l['offset'] = l['rowOffset'];
        delete l['rowOffset'];
        return l;
      }});
      props.vehicles.updater(newList);
    }

    const removeListingFromState = (index)=>{
      var newList = update(props.vehicles, {$apply: (l)=>{
        delete l['list'][index]
        return l;
      }});
      props.vehicles.updater(newList);
    }

    return  <Table.Body>
    {props.vehicles.list.map( (item, ind)=>{
      console.log(item)
      return <TableRow 
            key={item.vin}
            vehicle={item}
            columns={props.columns}
            updater={()=>{
              var newList = update(props.vehicles, {$apply: (l)=>{
                l['list'][ind]['expanded'] = !l['list'][ind]['expanded'];
                l['list'][ind]['read_at'] = Date.now();
                l['offset'] = l['rowOffset'];
                delete l['rowOffset'];
                return l;
              }});
              props.vehicles.updater(newList);
            }}
            markAsFollowed={(followed)=>{
              console.log('value: '+followed);
              setListingFollowedInState(ind, followed)
            }}
            markAsRead={()=>setListingReadInState(ind)}
            removeListingFromState={()=>{removeListingFromState(ind)}}
            disqualifier={()=>{
              var newList = update(props.vehicles, {$apply: (l)=>{
                l['list'].splice(ind,1);
                return l;  
              }})
              props.vehicles.updater(newList);
            }}
            channel={props.channel}
            />
        })
      }
</Table.Body>
}