import React from 'react'
import {Button} from 'semantic-ui-react'
import styled from 'styled-components'


const FlexWrapper = styled.div`
    display:flex;
    align-items: space-around
`
const Col = styled.div`
    width:46%
`
const CTA = styled.div`
    padding:1em 0;
`

export default ({carfax})=>{

    const {accident, owner, serviceRecord, useType} = carfax['dealerReport']['fourPillars']

    return(<>
            <FlexWrapper>
                <Col>
                    <div className="carfax-row">
                        <img src={accident['iconUrl']} className="carfax-icon"/>
                        <div className="flexgrow">
                            {accident['iconText']}
                        </div>
                    </div>
                    <div className="carfax-row">
                        <img src={owner['iconUrl']} className="carfax-icon"/>
                        <div className="flexgrow">
                            {owner['iconText']}
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="carfax-row">
                        <img src={serviceRecord['iconUrl']} className="carfax-icon"/>
                        <div className="flexgrow">
                            {serviceRecord['recordCount']} Service History Record{serviceRecord['recordCount']!= 1 && 's'}
                        </div>
                    </div>
                    <div className="carfax-row">
                        <img src={useType['iconUrl']} className="carfax-icon"/>
                        <div className="flexgrow">
                            {useType['iconText']}
                        </div>
                    </div>
                </Col>
            </FlexWrapper>
            <CTA>
                <center>
                    <Button color="green" href={carfax['dealerReport']['carfaxLink']['url']} target="_blank">
                        Full CARFAX Report
                    </Button>
                </center>
            </CTA>
        </>
    )
}