import React from 'react';
import StyledHeader from './StyledHeader';
import Badges from './Badges';
import styled from 'styled-components'

import {useHistory} from 'react-router-dom'

const FlexBox = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: start;
  align-items: center;
 `

 const Selector = styled.select`
   padding: 3px;
   color:#333333;
   display:block;
   margin-left:1em;
 `


const containerStyles = {
  width: '200px',
};

export default props => {
    let history = useHistory()
    const handleChange = (e)=>{
      var newChannel = e.target.options[e.target.selectedIndex].value;
      history.push('/channel/'+newChannel)
    }

    return (
      <section>
        <StyledHeader className="rainbow-align-content_space-between rainbow-p-vertical_medium react-rainbow-global-header">
            <div className="rainbow-m-left_medium flexgrow ">
              <FlexBox>
                <h1>OFF LEASE ONLY STATIC AUCTIONS TOOL</h1>
                <div>
                  <Selector onChange={handleChange} name="channel">
                  <option value="ove" selected={props.channel == 'OVE'}>OVE</option>
                  <option value="DealerBlock" selected={props.channel == 'DealerBlock'}>DealerBlock</option>
                  <option value="bmw" selected={props.channel == 'BMW_GROUP_DIRECT'}>BMW</option>
                  </Selector>
                </div>
              </FlexBox>
                
            </div>
            <Badges vehicles={props.vehicles} filterOptions={props.filterOptions}  channel={props.channel}/>
            <h2 className="rainbow-m-right_medium">Hello, {props.acct.account.username.split("@")[0]}</h2>
        </StyledHeader>
      </section>
    )
}
