import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import TableRowDetailHeader from '../TableRowDetailHeader/TableRowDetailHeader';
//import DetailTabs from '../DetailTabs/DetailTabs';
import VehicleManager from '../../../../../services/vehicles/VehicleManager';
import SingleView from '../SingleView/SingleView';

const DetailWrapper = styled.div`
    padding:1em;
    background:#F6f6f6;
`

const LoadingWrapper = styled.div`
    padding:3em;
    text-align:center;
`

const Link = styled.a`
    text-decoration:underline;
    font-weight:bold;
`

export default function(props) {

    const [vehicle, setVehicle] = useState({details: {}, loading: true});
   // console.log(vehicle);
    useEffect( ()=>{
        setVehicle({details:{...props.vehicle}, loading: true, error: false})
        var v = new VehicleManager();

        const formatDate = (item)=> { return item < 10 ? "0"+item : item}
        var d = (props.channel == 'DealerBlock') ? new Date(props.vehicle.auctionStartDate) : false;
        let dateStr = (d) ? d.getFullYear() +"-"+formatDate(d.getMonth()+1) + "-"+formatDate(d.getDate()) : false;

        v.getVehicleDetail(props.vehicle.vin, 'listing', props.channel, dateStr).then(
            (results)=>{
                var details = {...props.vehicle, ...results.data['listing']};
                setVehicle({details: details, loading: false, error: false});
                v.markAsRead(props.vehicle.vin, props.channel);
            }
        ).catch((e)=>{
            setVehicle({details:{}, loading: false, error: true});
        })
    }, [props.vehicle, props.fetcher, props.channel])

    const bodyObj = ()=>{

        if(vehicle.error){
            return <LoadingWrapper>
                <h1>
                    Error, vehicle can't be viewed. 
                    <Link onClick={(e)=>{e.stopPropagation();props.updater();}}>Close</Link>
                </h1>
            </LoadingWrapper>
        }

        return <>
            <TableRowDetailHeader 
                vehicle={vehicle.details} 
                updater={props.updater} 
                disqualifier={props.disqualifier} 
                channel={props.channel}
                markAsFollowed={props.markAsFollowed}
            />
            <SingleView vehicle={vehicle.details} loading={vehicle.loading} channel={props.channel} />         
        </>
    }
    return <DetailWrapper>
        {bodyObj()}
    </DetailWrapper>
}


/*
            <DetailTabs vehicle={vehicle.details}/>

*/