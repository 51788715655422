import axios from 'axios';


export const CarFaxEffect = (vin, token)=>{

    var graphQLQuery = {"query": `query ($vin: Vin!) {
        dealerReport(vin: $vin) {
            carfaxLink{
                url
                expiresAt{
                    unix
                    unixMillis
                    rfc
                }
            }
            snapshotKey
            expiresAt{
                    unix
                    unixMillis
                    rfc
            }
            vehicle{
                vin
            }
            fourPillars {
                accident {
                    iconUrl
                    iconText
                    searchableText
                    position
                    hasAccidents
                }
                owner {
                    iconUrl
                    iconText
                    searchableText
                    position
                    isOneOwner
                }
                useType {
                    iconUrl
                    iconText
                    searchableText
                    position
                    isPersonalUse
                }
                serviceRecord {
                    iconUrl
                    iconText
                    searchableText
                    position
                    recordCount
                }
                fourPillarsEnabled
            }
        }
    }`, "variables": {"vin": vin}}

    var headers = {
        'Authorization': 'Bearer '+token,
        'Content-Type': 'application/json'
    }

    return axios({
        url: 'https://connect.carfax.com/v1/graphql',
        method:"POST",
        headers:headers,
        data: graphQLQuery
    })
}