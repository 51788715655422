import React, {useState} from 'react'
import StyledFilterContainer from './StyledFilterContainer';
import FilterWrapper from './FilterWrapper';
import FilterNavigation from './FilterNavigation/FilterNavigation';
import FilterHeadline from './FilterHeadline/FilterHeadline';
import styled from 'styled-components';

const Shaded = styled.div`
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index:5;
    background:rgba(0,0,0,0.7);
`

export default ({filters, filterOptions, setFilterOptions}) =>{
    const [open, setOpen] = useState(false);

   return <FilterWrapper>
     <StyledFilterContainer className={ (open) ? "open" : ""}>
      <FilterHeadline updateFilterState={
        ()=>{
          setOpen(!open)
        }
      }/>
     <FilterNavigation filters={filters} open={open} filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
  </StyledFilterContainer>
    {open && <Shaded onClick={ ()=>{setOpen(false)} }/> }
  </FilterWrapper>
}