import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.span`
  
`
function numberWithCommas(x) {
    var num = parseInt(x)
    if(isNaN(num)) return x;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default ({value}) =>{
    return <StyledItem>{numberWithCommas(value)}</StyledItem>
}
 