import React, {useState, useEffect} from 'react';
import BidHistoryTree from './BidHistoryTree';
import {Table} from 'semantic-ui-react';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';
import StyledNumber from '../../../StyledNumber';
import StyledMoney from '../../../StyledMoney';

export default ({vin, vehicle})=>{

    const [bidHistory, setBidHistory] = useState({});
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    var cTree = new BidHistoryTree();

    useEffect( ()=>{
        var ignore = false;
        if(!vin) return;

        var v = new VehicleManager();

        setLoading(true);
        setTree([])
        setBidHistory([]);
        v.getAuctionVehicles(vin).then( (data)=>{
           if(!ignore){
             //  if(data.error) return addError(data.message, 500);
               var compData = cTree.formatResults(data.data.matches);
               setBidHistory(compData);
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
         //   addError(e.message, 500);
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin])


    useEffect(()=>{
        var t = []
        var crBand = cTree.findCRBand(vehicle.CRScore); 
        var band = cTree.findMileageBand(vehicle.mileage); 
        //console.log("current vehicle crBand, band,", crBand, band, vehicle.CRScore, vehicle.mileage, vehicle);
        if(bidHistory[crBand] != undefined){
            t.push(crBand)
        }
        if(t.length > 0){
            if(bidHistory[crBand]['children'][band] != undefined){
                t.push(band)
            }
        }
        setTree(t)
    }, [bidHistory, vehicle.mileage, vehicle.vehicleGrade])



    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    const setTableHeaders =()=> {
        var fields = cTree.setFields(tree);

        return (
            <Table.Row>
                {fields.map( (f) => {
                    return <Table.HeaderCell>{f.label}</Table.HeaderCell>
                })}
            </Table.Row>
        )
    }

    const outputBidHistory = ()=>{
        var fields = cTree.setFields(tree);
        var s = cTree.buildTableRows(bidHistory, tree);
        if(Array.isArray(s) && s.length > 0){
            //s.sort(sortBySaleDate);
            return s.map( (item,k)=>{
                return <Table.Row key={k}>
                    {
                    fields.map( (f) => {
                        switch(f.key) {
                            case "mileage":
                                if (item.mileage) {
                                    return <Table.Cell><StyledNumber value={item.mileage}/></Table.Cell>
                                } else {
                                    return <Table.Cell>Unknown</Table.Cell>
                                }
                            case "sold_price":
                                return <Table.Cell><StyledMoney value={item.sold_price} price={true}/></Table.Cell>
                            case "price":
                                if(isNaN(item.price)) return <Table.Cell>-</Table.Cell>;
                                return <Table.Cell><StyledMoney value={item.price} price={true}/></Table.Cell>
                            case "band":
                                return <Table.Cell><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></Table.Cell>
                            case "crBand":
                                return <Table.Cell><a className="olo_link" onClick={()=>{addToTree(item.crBand)}}>{item.crBand}</a></Table.Cell>
                            case "auction_vin":
                                return <Table.Cell>
                                    {item.auction_vin}
                                    <div><small>{item.vehicleGrade ? item.vehicleGrade: 'Unknown CR'} / { item.mileage ? <StyledNumber value={item.mileage}/> : 'Unknown'}  miles</small></div>
                                </Table.Cell>
                            case "auction_timestamp":
                                return <Table.Cell>{item.auction_timestamp ? item.auction_timestamp.substr(0,10): item.auction_timestamp}</Table.Cell>
                            default:
                                return <Table.Cell>{item[f.key]}</Table.Cell>
                            }
                    })
                    }
                </Table.Row>
            })
        }
        else if(loading){
            return <Table.Row>
            <Table.Cell colSpan="4">Checking for auction history...</Table.Cell>
        </Table.Row>
        }
        else{
            return <Table.Row>
                <Table.Cell colSpan="4">No Auction History Data Available</Table.Cell>
            </Table.Row>
        }
    }

    return(
        <div>
        <div className="d-flex pb-3">
            <span onClick={()=>{sliceTree(0)}} size="sm" color="secondary"><u>All</u>&nbsp;&nbsp;</span>
            {tree.map((item)=>{
                return <span onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">&nbsp;&nbsp;<u>{item}</u></span>
            })}
        </div>

        <Table>
            <Table.Header>{setTableHeaders()}</Table.Header>

            {outputBidHistory()}
        </Table>
        </div>
    )

}
