import React from 'react';
import styled from 'styled-components';

const Column = styled.div`
    width:50%;
    min-width:300px;
`

const FlexWrapper = styled.div`
    display: flex;
    align-items:stretch;
    &.top {
        align-items:top;
    }
`

const Label = styled.div`
    width:50%;
    background:#F2F2F2;
    padding:1em;
    font-weight:bold;
`

const Value = styled.div`
    width:50%;
    flex-grow:1;
    background:#FFFFFF;
    padding:1em;
`

var parseDate = (dateVal)=>{
    var d = new Date(dateVal);
    var now = new Date();
    var tzAdjustedDate = new Date( d.getTime() - (now.getTimezoneOffset()*60*1000 ) );
    return tzAdjustedDate.toLocaleString('en-US', { timeZone: 'America/New_York' })
}




export default ({vehicle, channel})=>{

    const indexMap = {
        "OVE":[
            {value:'auctionId', label: 'Auction ID'},
            {value:'auctionStartDate', label: 'Start Date', output: (v)=>{
                return parseDate(v['auctionStartDate']);
            }},
            {value:'auctionEndDate', label: 'End Date', output: (v)=>{
                return parseDate(v['auctionEndDate']);
            }},    
            {value:'channel', label: 'Channel'},
            {value:'crURL', label: 'CR URL', output: (v)=>{
                return <a href={v['crURL']} target="_blank" rel="noopener noreferrer">open CR</a>
            }},
            {value:'createdDate', label: 'Created Date', output: (v)=>{
                return parseDate(v['createdDate']);
            }},
            {value:'locationZip', label: 'Location ZIP'},    
            {value:'pickupRegion', label: 'Pickup Region'},
            {value:'vdpURL', label: 'VDP URL', output: (v)=>{
                return <a href={v['vdpURL']} target="_blank" rel="noopener noreferrer">open VDP</a>
            }}
        ],
        "DealerBlock":[
            {value:'auctionStartDate', label: 'Start Date', output: (v)=>{
                return parseDate(v['auctionStartDate']);
            }},
            {value:'auctionEndDate', label: 'End Date', output: (v)=>{
                return parseDate(v['auctionEndDate']);
            }},    
            {value:'channel', label: 'Channel', output:(v)=>{return 'DealerBlock'}},
            {value:'crURL', label: 'CR URL', output: (v)=>{
                return <a href={v['crURL']} target="_blank" rel="noopener noreferrer">open CR</a>
            }},
            {value:'locationZip', label: 'Location ZIP'},    
            {value:'vdpURL', label: 'VDP URL', output: (v)=>{
                return <a href={v['vdpURL']} target="_blank" rel="noopener noreferrer">open VDP</a>
            }}
        ],
        "BMW_GROUP_DIRECT":[
            {value:'auctionId', label: 'Auction ID'},
            {value:'auctionStartDate', label: 'Start Date', output: (v)=>{
                return parseDate(v['auctionStartDate']);
            }},
            {value:'auctionEndDate', label: 'End Date', output: (v)=>{
                return parseDate(v['auctionEndDate']);
            }},    
            {value:'channel', label: 'Channel'},
            {value:'crURL', label: 'CR URL', output: (v)=>{
                return <a href={v['crURL']} target="_blank" rel="noopener noreferrer">open CR</a>
            }},
            {value:'createdDate', label: 'Created Date', output: (v)=>{
                return parseDate(v['createdDate']);
            }},
            {value:'locationZip', label: 'Location ZIP'},    
            {value:'pickupRegion', label: 'Pickup Region'},
            {value:'vdpURL', label: 'VDP URL', output: (v)=>{
                return <a href={v['vdpURL']} target="_blank" rel="noopener noreferrer">open VDP</a>
            }}
        ],
    }

    const indices = indexMap[channel]


    var leftRow = indices.splice(0, Math.ceil(indices.length / 2));

    return <FlexWrapper>
            <Column>
                {leftRow.map( (item, index)=>{
                    return <FlexWrapper key={index}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
            <Column>
                {indices.map( (item)=>{
                    return <FlexWrapper key={item.value}>
                    <Label>{item.label}</Label>
                    <Value>{item.output ? item.output(vehicle) : ""+vehicle[item.value]}</Value>
                </FlexWrapper>
                })}
            </Column>
    </FlexWrapper>
    
}