import React from 'react';
import styled from 'styled-components';
import {Button} from 'semantic-ui-react';

const Wrapper = styled.div`
    padding:3em 0;
    text-align:center;
`

export default (props)=>{

    return <Wrapper>
        <Button href={props.url} target="_blank">Open Condition Report</Button>
    </Wrapper>
}