import React, {useState} from 'react';
import {Modal, Header, TextArea, Form, Dropdown} from 'semantic-ui-react';

export default (props)=>{

    const [textValue, setTextValue] = useState("")

    const DQ_Quick_Replies = [
        {
            key: '',
            text:'Select Quick Reply',
            value: ''
        },
        {
            key: 'Bad Seller',
            text: 'Bad Seller',
            value: 'Bad Seller'
        },
        {
            key: 'Bad Car Data',
            text: 'Bad Condition Report Data',
            value: 'Bad Condition Report Data'
        },
        {
            key: 'Invalid Vehicle',
            text: 'Canadian/Invalid Vehicle Type',
            value: 'Canadian/Invalid Vehicle Type'
        }
    ]

    const captureTextChange = (e, {value})=>{
        setTextValue(value);
        props.captureComment(e,{value: value});
    }

    return (
        <><Modal.Header>Disqualify VIN #{props.vin}</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Header>Enter a Diqualification Reason</Header>
                <Dropdown 
                    placeholder="Select Quick Reply"
                    fluid selection 
                    options={DQ_Quick_Replies}
                    onChange={captureTextChange}
                /><br/>
                <Form>
                    <TextArea onChange={captureTextChange} value={textValue}/>
                </Form>
            </Modal.Description>
        </Modal.Content>
        </>
    )
}