import React from 'react';
import styled from 'styled-components';
import {Dropdown, Menu, Button} from 'semantic-ui-react';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';
import AuctionButton from './AuctionButton/AuctionButton'
import FollowButton from './FollowButton/FollowButton';

const Controls = styled.div`
    padding:0 1em;
    display:flex;
    align-items: center;
`

const ControlWrapper = styled.div`
    padding:0 1em;
`

export default ({vehicle, setDQModal, channel, markAsFollowed})=>{

    const recordClickEvent = ()=>{
        let v = new VehicleManager();
        v.markAsClicked(vehicle.vin, channel);
    }

    const recordFollowUp = (followUpdate)=>{
        let v = new VehicleManager()
        var data ={
            vin: vehicle.vin,
            followUp: followUpdate
        }
        v.markAsFollow(data, channel)
        markAsFollowed(followUpdate)
    }

    return (
        <Controls>
            <FollowButton channel={channel} vehicle={vehicle} recordClickEvent={recordFollowUp}/>
            <ControlWrapper>
                <Menu compact size="mini">
                    <Dropdown item pointing text='DQ'>
                        <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>{setDQModal(true)}}>Disqualify VIN</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
            </ControlWrapper>
            <AuctionButton channel={channel} vehicle={vehicle} recordClickEvent={recordClickEvent}/>
        </Controls>
    )
}