import React from 'react'
import { Table } from 'semantic-ui-react'
import StyledTable from './StyledTable';
import TableBody from './TableBody';
import TableError from './TableError';
import Pagination from '../Pagination/Pagination';

const Table2 = (props) => (
<StyledTable>
  
  <Table celled>

    <Table.Header>
      <Table.Row>
        {
          Object.keys(props.columns).map((item, key)=>{
            return (props.columns[item].active) ? <Table.HeaderCell key={key}>{props.columns[item].label.toUpperCase()}</Table.HeaderCell> : null;
          })
        }
      </Table.Row>
    </Table.Header>

    {
      props.vehicles.error ? 
        <TableError vehicles={props.vehicles}/> : 
        <TableBody vehicles={props.vehicles} columns={props.columns} channel={props.channel}/>
    }
    
  </Table>

  <Pagination 
    totalRows={props.vehicles.totalRows} 
    offset={props.vehicles.rowOffset} 
    filters={props.vehicles.filters}
  />

</StyledTable> 
)

export default Table2