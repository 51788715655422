import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import StyledNumber from '../../../../StyledNumber';
import {Table} from 'semantic-ui-react';
import VehicleManager from '../../../../../../../services/vehicles/VehicleManager';



export default ({vin})=>{

    const [daySupply, setDaySupply] = useState("...")
    const [loading, setLoading] = useState(false)

    useEffect( ()=>{
        var ignore = false;

        var VM = new VehicleManager();

        if(vin){

            setLoading(true)
            VM.getNetDemand(vin).then((res)=>{
                setLoading(false);
                if(res.data){
                    var liveSupply = res.data.LiveInventory + res.data.NumInPrep;
                    
                    if(isNaN(liveSupply)) return setDaySupply('Unknown');

                    //if we dont have any inventory, days supply must be 0
                    if(liveSupply < 1) return setDaySupply(0);
                     var current_days_supply = liveSupply/( ((liveSupply+1)**res.data.NetDemand)-1);
                     setDaySupply(Math.round(current_days_supply))
                }
                else{
                    setDaySupply("Unknown")
                }


            }).catch((e)=>{
                console.log(e);
                if(!ignore) setLoading(false);
            })

        }

        return ()=>{ignore = true}
    }, [vin])

    return(
        <>{daySupply} days supply</>
    )

}