import React from 'react';
import styled from 'styled-components';
import {Button, Table} from 'semantic-ui-react';

const Wrapper = styled.div`
    padding:2em 0;
    text-align: center;
`


export default (props)=>{

    return <Table.Body>
        <Table.Row>
            <Table.Cell colSpan={13}>
                <Wrapper>
                    Something went wrong, please try your request again.
                    <br/><br/>
                    <Button primary onClick={()=>props.vehicles.setRefresher(Date.now())}>Retry Request</Button>
                    <Button onClick={()=>props.vehicles.filters.updater({})}>Reset Listings</Button>
                </Wrapper>
            </Table.Cell>
        </Table.Row>
    </Table.Body>
}