import React, {useState, useEffect} from 'react';
import {Table, Button} from 'semantic-ui-react';
import styled from 'styled-components';
import VehicleManager from '../../../../../services/vehicles/VehicleManager'

const KeyWrapper = styled.div`
    padding:1em 0;
    flex-grow:1;


    h2 {
        font-size:1.1em;
        font-weight:700;
    }
`


export default ({vin}) =>{
    const [keys, setKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadKeys, setLoadKeys] = useState(false)

    useEffect( ()=>{
        var ignore = false;
        if(!vin || !loadKeys) return;
        setLoading(true)
        var v = new VehicleManager();
        v.getVehicleDetail(vin, 'keys').then( (result)=>{
            if(!ignore) setKeys(result.data['keys']);
            setLoading(false)
        }).catch(()=>{setLoading(false)})
        return ()=>{ignore = true;}
    },[vin, setLoading, loadKeys])

    const loadKeysData = (e)=>{
        e.preventDefault();
        setLoadKeys(true);
    }

    return <KeyWrapper>
        <Table celled>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {keys && keys.map( (key, index)=>{
                return <Table.Row key={index}>
                    <Table.Cell>{key.type}</Table.Cell>
                    <Table.Cell>{key.quantity}</Table.Cell>
                </Table.Row>
            } )}
            {loading && <Table.Row><Table.Cell colSpan="2"><Button disabled>Loading Keys...</Button></Table.Cell></Table.Row>}
            {!loadKeys && <Table.Row><Table.Cell><Button onClick={loadKeysData}>Load Keys</Button></Table.Cell></Table.Row>}
            </Table.Body>
        </Table>
    </KeyWrapper>

}