import React, {useState, useEffect} from 'react';
import {Table} from 'semantic-ui-react';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';
import StyledNumber from '../../../StyledNumber';
import StyledMoney from '../../../StyledMoney';
import CompetitorTree from './CompetitorTree';

export default ({vin, vehicle, channel})=>{

    const [currentInventory, setCurrentInventory] = useState({});
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    var cTree = new CompetitorTree();

    useEffect( ()=>{
        var ignore = false;
        if(!vin) return;
        var v = new VehicleManager();
        setLoading(true);
        setTree([])
        setCurrentInventory([]);
        v.getStaticVehicleDetail(vin, "comps", channel).then( (data)=>{
           if(!ignore){
             //  if(data.error) return addError(data.message, 500);
               var compData = cTree.formatResults(data.data.comps);
               setCurrentInventory(compData);
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin, channel])


    useEffect(()=>{
        var t = []
        var band = cTree.findMileageBand(vehicle.mileage); 
        if(currentInventory[band] != undefined){
            t.push(band)
        }
        if(t.length > 0){
            if(currentInventory[band]['children'][vehicle.year] != undefined){
                t.push(vehicle.year)
            }
        }
        setTree(t)
    }, [currentInventory, vehicle.mileage, vehicle.year])



    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    const setTableHeaders =()=> {
        var fields = cTree.setFields(tree);

        return (
            <Table.Row>
                {fields.map( (f) => {
                    return <Table.HeaderCell>{f.label}</Table.HeaderCell>
                })}
            </Table.Row>
        )
    }

    const outputData = ()=>{
        var fields = cTree.setFields(tree);
        var s = cTree.buildTableRows(currentInventory, tree);
        if(Array.isArray(s) && s.length > 0){
            //s.sort(sortBySaleDate);
            return s.map( (item,k)=>{
                return <Table.Row key={k}>
                    {
                    fields.map( (f) => {
                        switch(f.key) {
                            case "mileage":
                                if (item.mileage) {
                                    return <Table.Cell><StyledNumber value={item.mileage}/></Table.Cell>
                                } else {
                                    return <Table.Cell>Unknown</Table.Cell>
                                }
                            case "current_list_price":
                                return <Table.Cell><StyledMoney value={item.current_list_price} price={true}/></Table.Cell>
                            case "price":
                                if(isNaN(item.price)) return <Table.Cell>-</Table.Cell>;
                                return <Table.Cell><StyledMoney value={item.price} price={true}/></Table.Cell>
                            case "band":
                                return <Table.Cell><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></Table.Cell>
                            case "year":
                                return <Table.Cell><a className="olo_link" onClick={()=>{addToTree(item.year)}}>{item.year}</a></Table.Cell>
                            case "competitor_vin":
                                return <Table.Cell>
                                    {item.competitor_vin}
                                    <div><small>{item.competitorModelYear ? item.competitorModelYear: 'Unknown Year'} / { item.mileage ? <StyledNumber value={item.mileage}/> : 'Unknown'}  miles</small></div>
                                </Table.Cell>
                            default:
                                return <Table.Cell>{item[f.key]}</Table.Cell>
                            }
                    })
                    }
                </Table.Row>
            })
        }
        else if(loading){
            return <Table.Row>
            <Table.Cell colSpan="4">Checking for competitor data...</Table.Cell>
        </Table.Row>
        }
        else{
            return <Table.Row>
                <Table.Cell colSpan="4">No Competitor Data Available</Table.Cell>
            </Table.Row>
        }
    }

    return(
        <div>
        <div className="d-flex pb-3">
            <span onClick={()=>{sliceTree(0)}} size="sm" color="secondary"><u>All</u>&nbsp;&nbsp;</span>
            {tree.map((item)=>{
                return <span onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">&nbsp;&nbsp;<u>{item}</u></span>
            })}
        </div>

        <Table>
            <Table.Header>{setTableHeaders()}</Table.Header>

            {outputData()}
        </Table>
        </div>
    )
}
