import React, {useState, useEffect} from 'react';
import Main from '../../components/main/Main';
import FilterContainer from '../../components/filter/FilterContainer/FilterContainer';
import VehicleManager from '../../services/vehicles/VehicleManager';
import Header from '../../components/header/Header';
import AppEffect from '../../AppEffect';



function App(props) {
  const [globalList, setGlobalList] = useState({list: [], loading: false, offset: 0, totalRows: 0, lastRefresh: 0, error: false});
  const [filters, setFilters] = useState({});
  const [sorter, setSorters] = useState(["createdDate_DESC"]);
  const [columns, setColumns] = useState([]);
  const [refresher, setRefresher] = useState(Date.now())
  const [filterOptions, setFilterOptions] = useState({});

  const Vehicles = new VehicleManager({
    list: globalList.list,
    loading: globalList.loading, 
    rowOffset: globalList.offset,
    totalRows: globalList.totalRows,
    updater: setGlobalList,
    error: globalList.error,
    lastRefresh: globalList.lastRefresh,
    setRefresher:setRefresher,
    filters: { list: filters, updater: setFilters },
    sorters: { value: sorter, updater: setSorters },
    columns: { list: columns, updater: setColumns }
  })

  useEffect( 
    AppEffect.globalList(filters, sorter, refresher, setGlobalList, "BMW_GROUP_DIRECT"),
    [filters, sorter, refresher]
  );
  
  useEffect(AppEffect.loadFilterOptions(setFilterOptions), []);

  return (
    <>        
        <FilterContainer filters={Vehicles.filters} filterOptions={filterOptions} setFilterOptions={setFilterOptions} channel="BMW_GROUP_DIRECT" /> 
        <Header vehicles={Vehicles} acct={props.acct} filterOptions={filterOptions} setFilterOptions={setFilterOptions} channel="BMW_GROUP_DIRECT"/>
        <Main vehicles={Vehicles}  channel="BMW_GROUP_DIRECT"/>
    </>
  );
}
export default App;
