import * as msal from "@azure/msal-browser"
import MSALConfig from '../config/MSALConfig'

const msalConf = {
    auth: {
        clientId: MSALConfig.clientId,
        authority: "https://login.microsoftonline.com/"+MSALConfig.tenant
    },
    cache: {
        cacheLocation: MSALConfig.cacheLocation
    }
}


export default new msal.PublicClientApplication(msalConf);