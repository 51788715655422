import React from 'react';
import { PicklistOption } from 'react-rainbow-components';
import styled from 'styled-components';
import PickupLocation from './PickupLocation/PickupLocation';
import NetDemand from './NetDemand/NetDemand';


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const DetailLabel = styled.div`
    font-size:0.8em;
    text-align:left;
    text-transform: uppercase;
    font-weight:bold;
    padding:1em;
    margin:2em 0 0 0;
    background:#303960;
    color:#FFFFFF;
`
const DetailItem = styled.div`
    width:50%;
`

const DetailValue = styled.div`
    padding:2em 1em;
    font-size:1.1em;
    text-align:left;
`

export default ({vehicle, channel})=>{
    return <Wrapper>
        <DetailItem>
            <DetailLabel>Seller</DetailLabel>
<DetailValue>{vehicle.sellerName} <small>{(Array.isArray(vehicle.sellerTypes) && vehicle.sellerTypes.length > 0) && '('+vehicle.sellerTypes.join(", ")+')'}</small></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>Pickup Location</DetailLabel>
            <DetailValue><PickupLocation vehicle={vehicle} channel={channel}/></DetailValue>
        </DetailItem>
        <DetailItem>
            <DetailLabel>Days Supply</DetailLabel>
            <DetailValue><NetDemand vin={vehicle.vin}/></DetailValue>
        </DetailItem>
    </Wrapper>
}