import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Filter from '../../Filter/Filter';
import RangeFilter from '../../RangeFilter/RangeFilter';
import AgeFilter from '../../SingleFilter/SingleFilter';
import TextFilter from '../../TextFilter/TextFilter';
import {VerticalNavigation} from 'react-rainbow-components';
import FilterHelper from './filterEffects';
import update from 'immutability-helper';
import {Button} from 'semantic-ui-react';
import BooleanFilter from '../../BooleanFilter/BooleanFilter';

 const FilterNavigation = styled.div`
    width:360px;
    padding:0 20px 0 20px;
    background: #FFFFFF;
    display:none;

    &.open {
      display:block;
    }
`

const ButtonWrapper = styled.div`
    padding:2em 0;
`

const listUpdaterFunc = (filters, index, value, updater) => {
  let f = {};
  f[index] = {
    $set: {
      'type': "list",
      'field': index,
      'acceptedValues': value
    }
  }
 
  if(index === "make" && Array.isArray(value) && value.length === 0){
    f["model"] = {
      $set: {
        'type':"list",
        'field':'model',
        'acceptedValues':[]
      }
    }
  }

  f['offset'] = {$set: {
    type:'range',
    field: 'offset',
    value: 0
  }};
  var newFilter = update(filters, f);
  updater(newFilter); 
}

const rangeUpdaterFunc = (filters, index, value, updater) =>{
  let f = {};
  f[index] = {
    $set: {
      'type': "range",
      'field': index,
      'value': value
    }
  }
  f['offset'] = {$set: {
    type:'range',
    field: 'offset',
    value: 0
  }};
  var newFilter = update(filters, f);
  updater(newFilter); 
  console.log("*** rangeUpdaterFunc", filters, f);
  //props.filters.updater(filters);
}

export default function FilterNav(props){
  var cars = props.filterOptions;
  var globalUpdater =  props.filters.updater
  const [filterData, updateFilterData] = useState({...props.filters.list});
  useEffect(FilterHelper.updateGlobalFilters(filterData,globalUpdater), [filterData]);
  
  useEffect(
    ()=>{
      var propStr = JSON.stringify(props.filters.list);
      var filterStr = JSON.stringify(filterData);
      if(propStr !== filterStr){
        updateFilterData({...props.filters.list})
      }
    },
    [props.filters.list]
  )
  
  const setFilterData = (newFilters)=> {
    updateFilterData(newFilters);
    props.filters.updater(newFilters);
  }

  return <FilterNavigation className={(props.open) ? "open" : ""}>
    <VerticalNavigation>
        {props.children}
        <Filter 
          label="Make"
          setFilterOptions={props.setFilterOptions}
          noResultsMessage="Click the Refresh Dropdown Data Link Below"
          options={FilterHelper.getMakes(cars)} 
          currentState={filterData['make']}
          handleOnChange={
            (e, {value})=>{listUpdaterFunc(filterData,'make', value, setFilterData)}
          }
        />
        <Filter 
          label="Model" 
          options={FilterHelper.getModels(cars,props.filters.list)}
          currentState={filterData['model']}
          noResultsMessage="Select a Make first"
          handleOnChange={
            (e, {value})=>{listUpdaterFunc(filterData,'model', value, setFilterData)}
          }
        />
        <TextFilter
          label="Seller"
          value={filterData['seller_include']}
          placeholder="Enter Seller"
          field="seller_include"
          updater={
            (fieldName, fieldValue)=>{
              rangeUpdaterFunc(filterData, fieldName, fieldValue, setFilterData);
            }
          }
        />

        <RangeFilter 
          label="Year" 
          field="year" 
          min={filterData['year_min']}
          max={filterData['year_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />
        <RangeFilter 
          label="CR Score" 
          field="CRScore" 
          min={filterData['CR_min']}
          max={filterData['CR_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />
        <RangeFilter 
          label="Mileage" 
          field="mileage" 
          min={filterData['mileage_min']}
          max={filterData['mileage_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

        <RangeFilter 
          label="Buy Now to MMR" 
          field="BuyNowToMMR" 
          min={filterData['BuyNowToMMR_min']}
          max={filterData['BuyNowToMMR_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

<RangeFilter 
          label="Current Bid to MMR" 
          field="CurrentBidToMMR" 
          min={filterData['CurrentBidToMMR_min']}
          max={filterData['CurrentBidToMMR_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

<RangeFilter 
          label="Buy Now to Black Book" 
          field="BuyNowToBlackBook" 
          min={filterData['BuyNowToBlackBook_min']}
          max={filterData['BuyNowToBlackBook_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

<RangeFilter 
          label="Current Bid to Black Book" 
          field="CurrentBidToBlackBook" 
          min={filterData['CurrentBidToBlackBook_min']}
          max={filterData['CurrentBidToBlackBook_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

        <RangeFilter 
          label="Days Supply" 
          field="daySupply" 
          min={filterData['daySupply_min']}
          max={filterData['daySupply_max']}
          updater={
            (fieldname, fieldvalue)=>{
              rangeUpdaterFunc(filterData, fieldname, fieldvalue, setFilterData); 
            }
          }
        />

        <Filter 
          label="Pickup Location" 
          options={FilterHelper.stateOptions}
          currentState={filterData['pickupLocationState']}
          handleOnChange={
            (e, {value})=>{listUpdaterFunc(filterData,'pickupLocationState', value, setFilterData)}
          }
        />

        <AgeFilter 
          label="Listing Age" 
          options={FilterHelper.ageFilters}
          currentState={filterData['ageOffset']}
          handleOnChange={
            (e, {value})=>{

              rangeUpdaterFunc(filterData,'ageOffset', value, setFilterData)
            
            }
          }
        />

        <AgeFilter 
          label="Auction End Date" 
          options={FilterHelper.endDateFilters}
          currentState={filterData['auctionEndMax']}
          handleOnChange={
            (e, {value})=>{

              rangeUpdaterFunc(filterData,'auctionEndMax', value, setFilterData)
            
            }
          }
        />

        <BooleanFilter 
          label="Show Expired Listings"
          name="showPastAuctionEndDate"
          checkboxText="Show listings that are past their end date"
          isChecked={filterData['showPastAuctionEndDate'] ? filterData['showPastAuctionEndDate'].value : false}
          updater={(name, value)=>{
            rangeUpdaterFunc(filterData, name, value, setFilterData)
          }}
        />

        
        <BooleanFilter 
          label=" Show Prequalified Listings"
          name="dqTestComplete"
          checkboxText="Only Show Prequalified Listings"
          isChecked={filterData['dqTestComplete'] ? filterData['dqTestComplete'].value : false}
          updater={(name, value)=>{
            rangeUpdaterFunc(filterData, name, value, setFilterData)
          }}
        />

        <BooleanFilter 
          label=" Show Listings I Follow"
          name="followUp"
          checkboxText="Only Show Followed Listings"
          isChecked={filterData['followUp'] ? filterData['followUp'].value : false}
          updater={(name, value)=>{
            rangeUpdaterFunc(filterData, name, value, setFilterData)
          }}
        />
     
        <ButtonWrapper>

            <Button onClick={()=>{
              setFilterData({})
            }}>Clear All Filters</Button>

        </ButtonWrapper>

    </VerticalNavigation>
  </FilterNavigation>
}