import VehicleManager from './services/vehicles/VehicleManager';

export default {
  
  globalList: (filters, sorter, refresher, setGlobalList, channel) => {
    return ()=>{
        var ignore = false;
        const v = new VehicleManager();
        setGlobalList({list:[], loading: true, offset: 0, totalRows: 0});
        v.getVehicles({filters: filters, sorter: sorter}, channel).then((data)=>{
          if(!ignore){
            var newList = data.data.vehicles;
            var offset = filters.offset ? filters.offset.value : 0;
            setGlobalList({
              list: newList, 
              loading: false,
              totalRows: data.data.total_count,
              offset: offset,
              lastRefresh: Date.now(),
              error:false
            });
          }
        }).catch((e)=>{
          setGlobalList({
            list: [], 
            loading: false,
            totalRows: 0,
            offset:0,
            lastRefresh: Date.now(),
            error:true
          })
        })
        return ()=>{ignore = true;}
    }
  },

  loadFilterOptions: (callback)=>{
    return ()=>{ var ignore = false;
        var v = new VehicleManager();
        v.getFilters().then( (result)=>{
            if(!ignore) callback(result.data['makes']);
        });
        return ()=>ignore = true;
    }
  }
}