import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import StyledNumber from '../../../StyledNumber';
import StyledMoney from '../../../StyledMoney';
import {Table} from 'semantic-ui-react';
import VehicleManager from '../../../../../../services/vehicles/VehicleManager';



export default ({vin})=>{

    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect( ()=>{
        var ignore = false;
        if(!vin) return;
        setLoading(true)
        var v = new VehicleManager();
        v.getVehicleDetail(vin, 'sales_history').then( (result)=>{
            if(!ignore) setSales(result.data['sales_history']);
            setLoading(false)
        }).catch(()=>{setLoading(false)})
        return ()=>{ignore = true;}
    },[vin, setLoading])

    const sortBySaleDate = (a,b)=>{
        var d1 = Date.parse(a['sold_date'])
        var d2 = Date.parse(b['sold_date'])
        return  d2 - d1;
    }

    const outputSalesHistory = (s)=>{
        if(Array.isArray(s) && s.length > 0){
            s.sort(sortBySaleDate);
            return s.map( (i,k)=>{
                return <Table.Row key={k}>
                    <Table.Cell>{i['store']}</Table.Cell>
                    <Table.Cell><StyledNumber value={i['mileage']}/></Table.Cell>
                    <Table.Cell><StyledMoney value={i['cash_price']}/></Table.Cell>
                    <Table.Cell>{i['days_live']}</Table.Cell>
                    <Table.Cell>{i['buyers_guide']}</Table.Cell>
                    <Table.Cell>{i['extcolor']}</Table.Cell>
                </Table.Row>
            })
        }
        else if(loading){
            return <Table.Row>
            <Table.Cell colSpan="4">Checking for recent sales...</Table.Cell>
        </Table.Row>
        }
        else{
            return <Table.Row>
                <Table.Cell colSpan="4">No Recent Sales Data Available</Table.Cell>
            </Table.Row>
        }
    }


    return(
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>STORE</Table.HeaderCell>
                    <Table.HeaderCell>MILEAGE</Table.HeaderCell>
                    <Table.HeaderCell>LIST PRICE</Table.HeaderCell>
                    <Table.HeaderCell>DAYS LIVE</Table.HeaderCell>
                    <Table.HeaderCell>WARRANTY</Table.HeaderCell>
                    <Table.HeaderCell>EXTERIOR</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            {outputSalesHistory(sales)}
        </Table>
    )
}