import React from 'react';
import ImageGallery from 'react-image-gallery';
import Loading from '../../../../../elements/Loading';

export default (props)=>{

    const images = [];
    var propImages = Array.isArray(props.images) ? [...props.images] : [];
    propImages.map((item)=>{
        return images.push({
            original: item.largeUrl,
            thumbnail: item.smallUrl
        })
    }) 

    const returnLogic = ()=>{
        if(props.loading) return <Loading />;
        
        if(images.length > 0){
           return <ImageGallery items={images} thumbnailPosition="left" useBrowserFullscreen={false}/>;
        }
        else{
            return "";
        }
    }
    
    return <>{returnLogic()}</>;

}