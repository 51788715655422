import React from 'react'
import {Button} from 'semantic-ui-react';




export default ({vehicle, recordClickEvent, channel})=>{

    const outputButton = ()=>{
        if(channel == 'DealerBlock'){
            return <Button primary href={vehicle.crURL} target="_blank" onClick={recordClickEvent}>Open Auction</Button>
        } 
        else{
            return <Button primary href={`https://www.ove.com/search/results#/details/${vehicle['vin']}`} target="_blank" onClick={recordClickEvent}>Open Auction</Button>

        }
    }


    return(
        <>{outputButton()}</>
    )
}